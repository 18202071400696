import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FilterPillButton } from 'components/atoms/buttons/filter-pill';
import { SortingButton } from 'components/atoms/buttons/sorting-button';
import { Typography } from 'components/atoms/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import FilterCarousel from './filter-carousel';
import {
  FilterPillButtonWrapper,
  ScrolledContainer,
  ScrolledFilterBox,
  SearchHeadingWrapper,
  WithoutScrollContainer,
  WithoutScrollFilterBox,
} from './styles';

type TProps = {
  title?: string;
  subTitle?: string;
  tasticsPlacementNumber?: number;
  data: any;
  optionsData: any;
  onClickFilterPill?: (data?: any) => void;
  filters?: any;
  showFilters?: boolean;
  defaultSelected?: string;
  eventTitle?: string;
  sortValue?: string;
  setSortValue?: (data?: string) => void;
  showSortingButton?: boolean;
};

const SearchFilters = ({
  title,
  tasticsPlacementNumber,
  subTitle,
  data,
  optionsData,
  onClickFilterPill,
  eventTitle,
  filters,
  showFilters,
  defaultSelected,
  setSortValue,
  sortValue,
  showSortingButton = true,
}: TProps) => {
  const [scrolled, setScrolled] = useState(false);
  const [isTablet] = useMediaQuery(tablet);
  const searchHeaderTitle = useRef(null);

  const handleScroll = () => {
    if (window.scrollY > searchHeaderTitle?.current?.offsetTop) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const filterButtons = useMemo(
    () =>
      data?.map((item, index) => {
        const selected =
          filters &&
          filters?.some(
            (dataInfo) =>
              dataInfo?.name?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase() ===
              item?.title?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase(),
          );
        return (
          <FilterPillButton
            defaultSelected={
              defaultSelected?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase() ===
                item?.title?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase() || selected
            }
            label={item?.title}
            key={index}
            onClick={onClickFilterPill}
          />
        );
      }),
    [data, filters],
  );

  return (
    <>
      <ScrolledContainer $isFixed={scrolled}>
        <ScrolledFilterBox data-testid="filter-pill-list-fixed">
          {showFilters && (
            <div className="md:flex md:flex-row md:gap-[20px]">
              {title && (
                <Typography variant="h5" className="mb-4 md:mb-0" data-testid="search-filters-title-fixed">
                  “{title}” <span className="text-grey-480">results</span>
                </Typography>
              )}
              {isTablet ? (
                <FilterPillButtonWrapper>{filterButtons}</FilterPillButtonWrapper>
              ) : (
                <FilterCarousel
                  data={data}
                  filters={filters}
                  onClickFilterPill={onClickFilterPill}
                  defaultSelected={defaultSelected}
                />
              )}
            </div>
          )}
          {showSortingButton && (
            <SortingButton
              card_list_type="grid"
              placement={tasticsPlacementNumber}
              card_list_title=""
              card_list_name={eventTitle}
              options={optionsData}
              label="Sort by"
              onSelect={setSortValue}
              sortValue={sortValue}
            />
          )}
        </ScrolledFilterBox>
      </ScrolledContainer>
      <WithoutScrollContainer $isInvisible={scrolled}>
        <SearchHeadingWrapper ref={searchHeaderTitle}>
          {title && (
            <Typography variant="h2" data-testid="search-filters-title-scroll">
              “{title}” <span className="text-grey-480">results</span>
            </Typography>
          )}
          {subTitle && (
            <Typography variant="h5" className="!text-grey-480">
              search instead for{' '}
              <span className="text-leaf underline decoration-wavy underline-offset-4">{subTitle}</span>
            </Typography>
          )}
        </SearchHeadingWrapper>
        <WithoutScrollFilterBox data-testid="filter-pill-list-scroll">
          {showFilters && (
            <>
              {isTablet ? (
                <FilterPillButtonWrapper>{filterButtons}</FilterPillButtonWrapper>
              ) : (
                <FilterCarousel
                  data={data}
                  filters={filters}
                  onClickFilterPill={onClickFilterPill}
                  defaultSelected={defaultSelected}
                />
              )}
            </>
          )}
          {showSortingButton && (
            <SortingButton
              placement={2}
              card_list_name="Search Results page"
              card_list_title=""
              card_list_type="Section"
              options={optionsData}
              label="Sort by"
              onSelect={setSortValue}
              sortValue={sortValue}
            />
          )}
        </WithoutScrollFilterBox>
      </WithoutScrollContainer>
    </>
  );
};

export default SearchFilters;
