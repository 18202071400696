import tw from 'tailwind-styled-components';

export const HeaderContainerWrapper = tw.div`
    mx-auto 
    grid 
    grid-cols-3 
    lg:grid-cols-11 
    items-center 
    justify-center 
    px-5  
    lg:px-[60px]
    py-2 
    md:py-5  
    lg:py-6
`;
