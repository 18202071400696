import React from 'react';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { THeaderShopUrlEnums } from 'helpers/types/organisms/global/header';
import { getMenuFiltersLink } from 'helpers/utils/common';
import { desktop } from 'helpers/utils/screensizes';
import { TInitialStateProps } from '..';
import HeaderShopListDesktop from './header-shop-list/header-shop-list-desktop';
import HeaderShopListMobile from './header-shop-list/header-shop-list-mobile';
import { HeaderShopWrapper } from './styles';

type TProps = {
  toggleModal: (name: string) => void;
  modal: TInitialStateProps;
  data: any;
};

const HeaderShop = ({ toggleModal, modal, data }: TProps) => {
  const [isDesktop] = useMediaQuery(desktop);
  const { currentDispensary, host } = useDispensary();
  const stateDealsLink = `${host}/dispensaries/${currentDispensary?.state?.slug.split('/')[2]}/deals`;
  const modalData = modal?.shop?.data.filter((item) => item !== null);
  modalData?.forEach((links) => {
    if (links && links?.navigationUrl) {
      if (links?.navigationUrl === THeaderShopUrlEnums.DEAL) {
        links.navigationUrl = stateDealsLink;
      }
      if (links?.navigationUrl === THeaderShopUrlEnums.MENU) {
        links.navigationUrl = getMenuFiltersLink();
      }
      switch (links?.navigationUrl) {
        case THeaderShopUrlEnums.CATEGORIES:
        case THeaderShopUrlEnums.BRANDS:
        case THeaderShopUrlEnums.USAGES:
          links['isDrawer'] = true;
          break;
        default:
          links['isDrawer'] = false;
          break;
      }
    }
  });
  return (
    <HeaderShopWrapper id="headerShop" data-testid="header-shop" className="openMenuAnimation">
      {isDesktop ? (
        <HeaderShopListDesktop data={data} modalShopData={modalData} />
      ) : (
        <HeaderShopListMobile toggleModal={toggleModal} modalShopData={modalData} />
      )}
    </HeaderShopWrapper>
  );
};

export default HeaderShop;
