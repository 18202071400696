import tw from 'tailwind-styled-components';

export const HeaderShopWrapper = tw.div`
    bg-leaf
    px-5
    pb-[104px]
    pt-6
    lg:px-[60px]
    lg:pt-[46px]

`;
