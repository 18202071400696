import Auth from 'helpers/utils/cognito';

export const clearUserCart = async (user, storeId) => {
  try {
    const userCarts = JSON.parse(user.attributes['custom:current_cart_id']);
    const copyUserCarts = { ...userCarts };
    delete copyUserCarts[storeId];
    await Auth.updateUserAttributes([
      {
        Name: 'custom:current_cart_id',
        Value: JSON.stringify(copyUserCarts),
      },
    ]);
  } catch (error) {
    console.error('ERROR clearing user cart');
  }
};
