import Image from 'next/image';
import { RiseLogoContainer } from './styles';

type FooterLogoProps = {
  logo: {
    file: string;
    name: string;
    width: number;
    height: number;
  };
  host: string;
};

const FooterLogo = ({ logo, host }: FooterLogoProps) => {
  return (
    <RiseLogoContainer>
      <a href={host} title="RISE Dispensaries Footer Logo">
        <Image
          src={logo.file}
          alt={logo.name}
          width={logo.width}
          height={logo.height}
          loader={(options) => options.src}
          placeholder="empty"
          priority
          unoptimized
        />
      </a>
    </RiseLogoContainer>
  );
};

export default FooterLogo;
