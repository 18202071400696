import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { TAgeGateDeniedProps } from 'helpers/types';
import {
  AgeGateDeniedContainer,
  AgeGateDeniedHeadingContainer,
  AgeGateDeniedImageContainer,
  AgeGateDeniedInnerContainer,
  AgeGateDeniedSubheadingContainer,
} from './denied-styles';

export const AgeGateDeniedComponent = ({
  deniedHeading,
  deniedImageSrc,
  deniedSubheading,
  under21Title,
  under21Description,
  under21CtaTitle,
  isOpen,
  onClick,
}: TAgeGateDeniedProps) => {
  return (
    <AgeGateDeniedContainer data-testid="age-gate-denied-container" $isMedical={isOpen}>
      <AgeGateDeniedInnerContainer $isMedical={isOpen}>
        {!isOpen && (
          <AgeGateDeniedImageContainer
            style={{
              backgroundImage: `${deniedImageSrc ? `url(${deniedImageSrc.url})` : 'unset'}`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            data-testid="age-gate-denied-image"
          />
        )}

        <AgeGateDeniedHeadingContainer $isMedical={isOpen}>
          <Typography
            variant="h2"
            className="uppercase lg:text-[76px] lg:leading-[68px] 2xl:text-[104px] 2xl:leading-[96px]"
            data-testid="age-gate-denied-heading"
          >
            {!isOpen ? deniedHeading : under21Title}
          </Typography>
        </AgeGateDeniedHeadingContainer>
      </AgeGateDeniedInnerContainer>
      <AgeGateDeniedSubheadingContainer $isMedical={isOpen}>
        {isOpen ? (
          <div className="flex w-[350px] flex-col gap-6 lg:w-[687px] xl:w-[670px]">
            <ContentfulRichText
              data={under21Description}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => {
                  return (
                    <Typography
                      variant="body-small"
                      className="!text-grey-480"
                      data-testid="age-gate-medical-subheading"
                    >
                      {children}
                    </Typography>
                  );
                },
              }}
            />
            <CommonButton
              color="green"
              button-type={'primary'}
              className="!w-full !text-center"
              onClick={onClick}
              data-testid="age-gate-medical-button"
            >
              {under21CtaTitle}
            </CommonButton>
          </div>
        ) : (
          <ContentfulRichText
            data={deniedSubheading}
            renderNodeOptions={{
              [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                  <Typography variant="h3" className="!text-grey-480" data-testid="age-gate-denied-subheading">
                    {children}
                  </Typography>
                );
              },
            }}
          />
        )}
      </AgeGateDeniedSubheadingContainer>
    </AgeGateDeniedContainer>
  );
};
