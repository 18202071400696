import tw from 'tailwind-styled-components';
export const HeaderChangeDispensaryMobileWrapper = tw.div`
    block 
    bg-leaf 
    lg:hidden
`;

export const HeaderChangeDispensaryMobileIconButton = tw.div`
    flex 
    w-full 
    flex-row 
    items-center 
    gap-5 
    pb-5
    px-5
`;

export const HeaderChangeDispensaryMobileList = tw.ul`
    flex 
    flex-col 
    gap-8 
    pt-2
    px-5
    h-[425px] 
    overflow-y-scroll
`;
