import tw from 'tailwind-styled-components';
type THeaderIconBox = {
  $variant: string;
};
export const HeaderLinkWrapper = tw.div`
    items-center 
    juestify-start 
    col-span-1 
    lg:col-span-5 
    flex
`;

export const HeaderLinkBox = tw.ul`
    relative 
    hidden 
    list-none 
    flex-row 
    flex-wrap 
    gap-10  
    lg:flex 
    cursor-pointer
    items-center
`;

export const HeaderIconBox = tw.button<THeaderIconBox>`
    p-0
    ${(p) => (p.$variant === 'dark' ? 'text-white' : 'text-leaf')}
`;

export const HeaderIconMobileCard = tw.div`
    col-span-1 
    flex 
    flex 
    items-center 
    justify-end  
    gap-6 
    lg:hidden
    relative
`;
