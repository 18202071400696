import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import {
  CustomerCenterTabContainer,
  ImageContainer,
  TextContainer,
} from 'components/molecules/tabs/customer-center-tab/styles';
import { communicationInitiated } from 'helpers/analytics/communication-initiated-track';
import { useChatEventTracking } from 'helpers/hooks/use-chat-event-tracking';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
interface CustomerCenterTabProps {
  description: string;
  icon: Record<string, any>;
  title: string;
  url: string;
  closeDrawer?(): void;
}

export const CustomerCenterTab = ({ description, icon, title, url, closeDrawer }: CustomerCenterTabProps) => {
  const isChatTab = url === '#chat';
  const { openChatEvent } = useChatEventTracking();
  const data = {
    method: 'chat',
    link_name: 'customer-center',
    link_type: 'hyperlink',
    communication_trigger: 'customer-center',
  };
  const openChatModal = () => {
    openChatEvent();
    communicationInitiated(data);
  };
  return (
    <CustomerCenterTabContainer>
      <ImageContainer>
        <Image
          objectFit="contain"
          src={icon.url}
          width={icon.width}
          height={icon.height}
          alt={icon.title}
          loader={(options) => options.src}
          data-testid="customerCenterTabImage"
        />
      </ImageContainer>
      <TextContainer>
        {/* TODO: change this to ICON Button */}
        <CommonButton
          data-testid="customerCenterTabTitle"
          className="mb-1 flex !p-0"
          as={`${isChatTab ? 'button' : 'a'}`}
          href={`${isChatTab ? null : url}`}
          color="green"
          button-type="tertiary"
          onClick={() => {
            closeDrawer();
            isChatTab ? openChatModal() : communicationInitiated(data);
          }}
        >
          {title}
          <ChevronRightIcon className="ml-2" />
        </CommonButton>
        <Typography data-testid="customerCenterTabDescription" variant="body-small">
          {description}
        </Typography>
      </TextContainer>
    </CustomerCenterTabContainer>
  );
};
