import tw from 'tailwind-styled-components';

export const HeaderChangeDispensaryDesktopWrapper = tw.div`
    hidden 
    grid-cols-9 
    lg:grid
`;

export const HeaderChangeDispensaryDesktopTitleBox = tw.div`
    col-start-1 
    col-end-4
`;

export const HeaderChangeDispensaryDesktopBox = tw.div`
    col-start-4 
    col-end-10 
    grid 
    grid-cols-7 
    gap-4
`;

export const HeaderChangeDispensaryDesktopList = tw.ul`
    col-span-4 
    grid  
    grid-flow-col g
    ap-x-6 
    gap-y-5 
    [grid-template-rows:repeat(10,minmax(0,1fr))]
`;

export const HeaderChangeDispensaryDesktopImageBox = tw.div`
    relative 
    col-span-3 
    flex 
    h-[360px] 
    w-full 
    justify-end
`;
