/* 
Commenting the component as the floating pill is changed to the bar
*/

// import { useState } from 'react';
// import dynamic from 'next/dynamic';
// import LocalizationIndicator from 'components/organisms/global/localization-indicatior';
// import useMediaQuery from 'helpers/hooks/useMediaQuery';
// import { smallMobile, tablet } from 'helpers/utils/screensizes';
// import GeolocationBar from 'components/organisms/global/geolocation-bar';

type TProps = {
  data: {
    hideOnMobile: boolean;
  };
  pageFolder: any;
};

const GeolocationTastics = ({}: TProps) => {
  // const DispensaryDrawer = dynamic(() => import('components/organisms/drawers/dispensary-drawer'));
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const { hideOnMobile } = data || {};
  // const [isMobile] = useMediaQuery(smallMobile);
  // const [isTablet] = useMediaQuery(tablet);
  return (
    <>
      {/* <GeolocationBar locationName={'test'} setDrawerOpen={setIsOpen} /> */}
      {/* <LocalizationIndicator
            locationName={currentDispensary?.dispensaries?.dispensaryInformation.title}
            setDrawerOpen={setIsOpen}
          /> */}
      {/* {isOpen && <DispensaryDrawer isOpen={isOpen} setDrawerOpen={setIsOpen} host={pageFolder?.host} />} */}
    </>
  );
};

export default GeolocationTastics;
