import Facebook from '../../../../public/icons/rise-icons/facebook.svg';
import Instagram from '../../../../public/icons/rise-icons/instagram.svg';
import Twitter from '../../../../public/icons/rise-icons/twitter.svg';

type Tprops = {
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
};

export const SocialIcons = ({ instagramUrl, facebookUrl, twitterUrl }: Tprops) => {
  const instagramLink = instagramUrl || 'https://www.instagram.com/rise.cannabis/';
  const facebookLink = facebookUrl || 'https://www.facebook.com/RISEdispensaries/';
  const twitterLink = twitterUrl || 'https://twitter.com/risecannabis';
  return (
    <div className="flex gap-6 text-leaf">
      <a
        target="_blank"
        id="instagram-icon"
        aria-label="Follow RISE on Instagram"
        href={instagramLink}
        rel="noreferrer"
        title="RISE Dispensaries on Instagram"
        className="h-6 w-6 md:h-8 md:w-8"
      >
        <Instagram />
      </a>

      <a
        target="_blank"
        id="facebook-icon"
        aria-label="Follow RISE on Facebook"
        href={facebookLink}
        rel="noreferrer"
        title="RISE Dispensaries on Facebook"
        className="h-6 w-6 md:h-8 md:w-8"
      >
        <Facebook />
      </a>

      <a
        target="_blank"
        id="twitter-icon"
        aria-label="Follow RISE on Twitter"
        href={twitterLink}
        rel="noreferrer"
        title="RISE Dispensaries on Twitter"
        className="h-6 w-6 md:h-8 md:w-8"
      >
        <Twitter />
      </a>
    </div>
  );
};
