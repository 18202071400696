import React from 'react';
import Image from 'next/image';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { TContenfulImageProp } from 'helpers/types';
import { getLinkTarget, getMenuFiltersLink } from 'helpers/utils/common';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import {
  HeaderShopListSectionImageBox,
  HeaderShopListSectionImageWrapper,
  HeaderShopListSectionWrapper,
} from './styles';
type TProps = {
  data: any;
  name: string;
  host?: string;
  image: TContenfulImageProp;
  handleOnMouse: (data: any) => void;
  ctaName?: string;
};
const HeaderShopListSection = ({ data, name, image, handleOnMouse, ctaName }: TProps) => {
  const handleOnPressLink = (title: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
      nav_position: 'header',
      link_secondary: name,
      link_final: title,
      link_primary: 'shop',
      link_type: 'hyperlink',
    });
  };
  return (
    <React.Fragment>
      <HeaderShopListSectionWrapper>
        {data &&
          data?.map((item, index) => (
            <li
              className="cursor-pointer"
              key={index}
              onMouseEnter={() =>
                handleOnMouse({
                  name,
                  image: item?.logo,
                })
              }
            >
              <Typography
                as="a"
                onClick={() => handleOnPressLink(item?.title)}
                variant="body-large"
                className="text-white"
                target={getLinkTarget(getMenuFiltersLink(item?.algoliaId, item?.algoliaValue))}
                href={getMenuFiltersLink(item?.algoliaId, item?.algoliaValue)}
              >
                {item?.title}
              </Typography>
            </li>
          ))}
        {ctaName && (
          <IconWithTextButton
            button-type="tertiary"
            color="white"
            icon={<ChevronRightIcon size={20} />}
            iconPosition="after"
            as="a"
            href={getMenuFiltersLink()}
            target={getLinkTarget(getMenuFiltersLink())}
            className="!p-0"
          >
            {ctaName}
          </IconWithTextButton>
        )}
      </HeaderShopListSectionWrapper>
      {image && (
        <HeaderShopListSectionImageWrapper>
          <HeaderShopListSectionImageBox>
            <Image
              src={image?.url}
              loader={(options) => options.src}
              alt={image?.title}
              height={image?.height}
              width={image?.width}
            />
          </HeaderShopListSectionImageBox>
        </HeaderShopListSectionImageWrapper>
      )}
    </React.Fragment>
  );
};

export default HeaderShopListSection;
