import PageSEOComponent from 'components/organisms/global/page-seo';

const PageSeoTastics = ({ data }: any) => {
  const parsedData = data?.seoData?.dataSource;
  if (!parsedData || parsedData.isError) {
    return <></>;
  }
  const seoData = parsedData?.data?.seo?.items[0]?.seoMetadata;

  return <PageSEOComponent data={seoData} />;
};

export default PageSeoTastics;
