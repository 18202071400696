import React from 'react';
import { Typography } from 'components/atoms/typography';
import { ReplaceUrlWithHost, getLinkTarget } from 'helpers/utils/common';
import { useAppSelector } from 'redux/hooks';
import { HeaderCannabisInsiderDesktopList, HeaderCannabisInsiderDesktopWrapper } from './styles';

type TProps = {
  data: any;
  onPressLink?: (name: string) => void;
  host?: string;
};

const HeaderCannabisInsiderDesktop = ({ data, host, onPressLink }: TProps) => {
  const isOh = useAppSelector((state) => state.hostReducer?.isCurrentHostOH);
  const state = useAppSelector((state) => state.dispensaryList?.currentDispensary);

  return (
    <HeaderCannabisInsiderDesktopWrapper className="openMenuAnimation">
      <HeaderCannabisInsiderDesktopList>
        {data &&
          data?.map((item, index) => {
            const url =
              !isOh && state?.state.state?.stateName?.toLowerCase() === 'ohio' && !item?.navigationUrl?.includes('blog')
                ? item?.navigationUrl?.replace('{{url}}', '{{url-oh}}')
                : item?.navigationUrl;
            return (
              <li className="cursor-pointer" key={index}>
                <Typography
                  onClick={() => onPressLink(item?.navigationLabel)}
                  as="a"
                  variant="h2"
                  className={'opacity-40 transition-opacity duration-300 ease-in-out hover:opacity-100'}
                  target={getLinkTarget(ReplaceUrlWithHost(url, host))}
                  href={ReplaceUrlWithHost(url, host)}
                  data-testid={`navItem${index}`}
                >
                  {item?.navigationLabel}
                </Typography>
              </li>
            );
          })}
      </HeaderCannabisInsiderDesktopList>
    </HeaderCannabisInsiderDesktopWrapper>
  );
};

export default HeaderCannabisInsiderDesktop;
