import React, { useState } from 'react';
import { useRouter } from 'next/router';
import SearchFilters from 'components/organisms/search/filters/search-filters';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateSearch } from 'redux/reducers/search';

const optionsData = ['Newest', 'Oldest'];

const HeroSearch = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { category, query } = router?.query;
  const searchText = query as string;
  const search: any = useAppSelector((state) => state?.search?.data || {});
  const data = [
    {
      title: `All (${search.articlesData + search.productsData + search.dispensariesData})`,
      count: Number(search?.articlesData + search?.productsData + search?.dispensariesData) || 0,
    },
    {
      title: `Products (${search.productsData})`,
      count: Number(search?.productsData) || 0,
    },
    {
      title: `Articles (${search.articlesData})`,
      count: Number(search?.articlesData) || 0,
    },
    {
      title: `Dispensaries (${search.dispensariesData})`,
      count: Number(search?.dispensariesData) || 0,
    },
  ].filter((item) => {
    return item?.count > 0;
  });
  const [sortValue, setSortValue] = useState(search?.sortValue || optionsData[0]);

  const handleFilterPill = (data: any) => {
    const pathname = router.asPath.split('?')[0];
    const newCategory = data?.name?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase();
    if (newCategory === 'all' || newCategory === category) {
      router.push({ pathname, query: { query } }, undefined, { shallow: true });
    } else {
      router.push(
        {
          pathname,
          query: { query, category: newCategory },
        },
        undefined,
        { shallow: true },
      );
    }
  };

  const handleSorting = (sort: string) => {
    setSortValue(sort);
    dispatch(
      updateSearch({
        sortValue: sort,
      }),
    );
  };

  return search?.productsData || search?.articlesData || search?.dispensariesData ? (
    <SearchFilters
      onClickFilterPill={handleFilterPill}
      data={data || []}
      showFilters={true}
      title={searchText || ''}
      optionsData={optionsData}
      defaultSelected={String(category || 'All') || ''}
      setSortValue={handleSorting}
      sortValue={sortValue}
      showSortingButton={false}
    />
  ) : (
    <></>
  );
};

export default HeroSearch;
