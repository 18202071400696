import React from 'react';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import HeaderStateDesktop from './header-state-desktop';
import HeaderStateMobile from './header-state-mobile';

const HeaderState = ({ data, toggleModal, image }: any) => {
  const [isDesktop] = useMediaQuery(desktop);
  const handleOnPressLink = (name: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_EXPANDED, {
      nav_position: 'header',
      link_primary: name,
      link_type: 'hyperlink',
    });
  };
  return (
    <div id="headerState">
      {isDesktop ? (
        <HeaderStateDesktop data={data} toggleModal={toggleModal} image={image} onPressLink={handleOnPressLink} />
      ) : (
        <HeaderStateMobile data={data} toggleModal={toggleModal} onPressLink={handleOnPressLink} />
      )}
    </div>
  );
};

export default HeaderState;
