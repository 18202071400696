import { addMonths, differenceInDays, formatDate, getDaysInMonth, getYear, isValid } from 'date-fns';

export const getAllMonths = () => {
  const today = new Date().setMonth(0);
  return Array.from({ length: 12 }, (_, index) => ({
    label: formatDate(addMonths(today, index), 'MMMM'),
    value: index + 1,
  }));
};

export const getAllDaysPerMonth = (month: string, birthYear: string) => {
  const birthMonth = parseInt(month);
  const birthOrCurrentYear = birthYear ? parseInt(birthYear) : getYear(new Date());

  const daysInMonth = getDaysInMonth(new Date(birthOrCurrentYear, birthMonth - 1));

  const formValuesForEachDay = [];

  for (let i = 1; i <= daysInMonth; i++) {
    formValuesForEachDay.push({ label: i, value: i });
  }

  // Hack to always show February 29th, regardless of current year selection, to account for leap years
  if (birthMonth == 2) {
    if (formValuesForEachDay.length !== 29) {
      formValuesForEachDay.push({ label: 29, value: 29 });
    }
  }

  return formValuesForEachDay;
};

export const listOfYearsSince = (yearsBack: number) => {
  const year = new Date().getFullYear();
  return Array.from({ length: yearsBack }, (v, i) => year - yearsBack + i + 1);
};

export const getYearFormValues = () => {
  const yearsSince1900 = listOfYearsSince(getYear(new Date()) - 1900);
  const yearsReversed = yearsSince1900.reverse();
  return yearsReversed.map((year) => {
    return { label: year, value: year };
  });
};

export type TValidateBirthdayInputProps = {
  birthMonth: number;
  birthDay: number;
  birthYear: number;
  alternativeAgeOfVerification: boolean;
};

export const validateBirthday = ({
  birthMonth,
  birthDay,
  birthYear,
  alternativeAgeOfVerification,
}: TValidateBirthdayInputProps) => {
  const birthday = new Date(birthYear, birthMonth, birthDay);

  if (isValid(birthday)) {
    const currentDate = new Date();
    const ageOfVerification = alternativeAgeOfVerification ? 18 : 21;
    const ageEnteredInDays = differenceInDays(currentDate, birthday);

    const isValid = ageEnteredInDays >= ageOfVerification * 365;
    const isMedical = ageEnteredInDays >= 18 * 365 && ageEnteredInDays < 21 * 365;

    return { isValid, isMedical };
  } else {
    return { isValid: false, isMedical: false };
  }
};
