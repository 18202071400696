import tw from 'tailwind-styled-components';

export const HeaderShopListSectionWrapper = tw.ul`
    col-start-5 
    col-end-8 
    mt-4 
    hidden 
    h-[410px] 
    grid-flow-col  
    gap-x-6 
    gap-y-5 
    [grid-template-rows:repeat(10,minmax(0,1fr))] 
    lg:grid
`;

export const HeaderShopListSectionImageWrapper = tw.div`
    col-start-9 
    col-end-11 
    mt-4 
    hidden 
    lg:grid
`;

export const HeaderShopListSectionImageBox = tw.div`
    relative 
    h-full 
    w-full 
    overflow-hidden 
    rounded-md
`;
