import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useAutoClosePopUp } from 'helpers/hooks/use-auto-close-popup';
import useDispensary from 'helpers/hooks/use-dispensary';
import { COMMON_COOKIES } from 'helpers/types/cookies';
import { THeaderVariantTypes } from 'helpers/types/organisms/global/header';
import { getCookie, getCurrentStateSnackbarCookieValue } from 'helpers/utils/cookies';
import { useAppSelector } from 'redux/hooks';
import { clearSearchState } from 'redux/reducers/search';
import GeolocationBar from '../geolocation-bar';
import HeaderCannabisInsider from './header-cannabis-insider';
import HeaderContainer from './header-container';
import HeaderNavbarMobile from './header-container/header-navbar-mobile';
import HeaderDispensaries from './header-dispensaries';
import HeaderSearch from './header-search';
import HeaderShop from './header-shop';
import HeaderShopSectionMobile from './header-shop/header-shop-section-mobile';
import { HeaderBox, HeaderWrapper } from './styles';

const DispensaryDrawer = dynamic(() => import('components/organisms/drawers/dispensary-drawer'));

export type TInitialStateProps = {
  extendedMenu: boolean;
  navbar: {
    isOpen: boolean;
    data?: any;
  };
  shop: {
    isOpen: boolean;
    data?: any;
  };
  categories: {
    isOpen: boolean;
    data?: any;
  };
  brands: {
    isOpen: boolean;
    data?: any;
  };
  usages: {
    isOpen: boolean;
    data?: any;
  };
  dispensaries: {
    isOpen: boolean;
  };
  changeDispensary: {
    isOpen: boolean;
    data?: any;
  };
  cannabisInsider: {
    isOpen: boolean;
    data?: any;
  };
  searchBar: {
    isOpen: boolean;
    data?: any;
  };
};

interface HeaderProps {
  data: any;
  dispensariesList: any;
  snackBarCookies: any;
  geoState: any;
  host: any;
  hideServiceLocation?: boolean;
}

const Header = ({ data, dispensariesList, snackBarCookies, geoState, host, hideServiceLocation }: HeaderProps) => {
  const headerLinkData = data?.sectionHeaderCollection?.items[0];
  const initialState: TInitialStateProps = {
    extendedMenu: false,
    navbar: { isOpen: false },
    shop: { isOpen: false, data: null },
    categories: { isOpen: false, data: null },
    brands: { isOpen: false, data: null },
    usages: { isOpen: false, data: null },
    dispensaries: { isOpen: false },
    changeDispensary: { isOpen: false, data: null },
    cannabisInsider: { isOpen: false, data: null },
    searchBar: { isOpen: false, data: null },
  };
  const [modal, setModal] = useState(initialState);
  const searchBarState = useAppSelector((state) => state?.search?.data?.searchBar);
  const router = useRouter();
  const dispatch = useDispatch();
  // const [isPageScrolled, setIsPageScrolled] = useState<boolean>(true);
  const { currentDispensary } = useDispensary();
  const isPageScrolled = true;
  const [variant, setVariant] = useState<THeaderVariantTypes>('light');
  const [addSnakbarCss, setAddSnackbarCss] = useState<boolean>(snackBarCookies);
  const [isDispensaryDrawerOpen, setIsDispensaryDrawerOpen] = useState<boolean>(false);

  const toggleModal = (modalId: string, modalData?: any) => {
    setModal((prevModal) => {
      const modalItem = {
        ...Object.keys(prevModal).reduce((modalItem, key) => {
          if (key === modalId) {
            modalItem[key] = {
              isOpen: !prevModal[key].isOpen,
              data: modalData || prevModal[key].data,
            };
          } else {
            modalItem[key] = { isOpen: false, data: prevModal[key].data };
          }
          return modalItem;
        }, {} as TInitialStateProps),
      };
      modalItem.extendedMenu = modalItem[modalId].isOpen || false;
      setVariant(modalItem?.extendedMenu ? 'dark' : 'light');
      if (searchBarState?.isOpen) dispatch(clearSearchState());
      return modalItem;
    });
  };

  const closeModal = () => {
    setModal(initialState);
    const chooseVariantByScroll = isPageScrolled ? 'dark' : 'light';
    setVariant(chooseVariantByScroll);
  };

  const dispensariesImage = headerLinkData?.navigationMenuCollection.items.filter(
    (item) => item?.navigationLabel?.toLowerCase() === 'dispensaries',
  )?.[0]?.navigationBanner;

  useEffect(() => {
    // const scrollChangeColor = () => {
    //   const state = window?.scrollY >= 90;
    //    setIsPageScrolled(state);
    // };
    // window?.addEventListener('scroll', scrollChangeColor);
    router.events.on('routeChangeComplete', () => {
      closeModal();
    });
    return () => {
      router.events.off('routeChangeComplete', () => {
        closeModal();
      });
      //window?.removeEventListener('scroll', scrollChangeColor);
    };
  }, []);

  useEffect(() => {
    const chooseVariantByScroll = isPageScrolled ? 'dark' : 'light';
    setVariant(modal.extendedMenu ? 'dark' : chooseVariantByScroll);
    const cookies = getCookie(COMMON_COOKIES.STICKY_BAR);
    const state = getCurrentStateSnackbarCookieValue(cookies, geoState);

    setAddSnackbarCss(state);
  }, [modal, isPageScrolled]);
  useAutoClosePopUp('rise-header', closeModal);
  return (
    <HeaderWrapper
      id="rise-header"
      className={addSnakbarCss ? null : 'visible-snack-bar-header'}
      data-testid="header-wrapper"
      $variant={variant}
    >
      <HeaderBox>
        <HeaderContainer
          data={headerLinkData}
          variant={variant}
          modal={modal}
          toggleModal={toggleModal}
          closeModal={closeModal}
          isPageScrolled={isPageScrolled}
        />
        {modal?.extendedMenu && !modal?.searchBar?.isOpen
          ? null
          : !hideServiceLocation && (
              <GeolocationBar
                locationName={
                  Boolean(currentDispensary?.dispensaries?.geolocationTitle)
                    ? currentDispensary?.dispensaries?.geolocationTitle
                    : currentDispensary?.dispensaries?.dispensaryInformation.title
                }
                setDrawerOpen={setIsDispensaryDrawerOpen}
              />
            )}
        {modal?.navbar.isOpen && (
          <HeaderNavbarMobile
            data={headerLinkData?.navigationMenuCollection?.items}
            host={host}
            toggleModal={toggleModal}
          />
        )}
        {modal?.shop.isOpen && <HeaderShop data={data} modal={modal} toggleModal={toggleModal} />}
        {modal?.cannabisInsider.isOpen && <HeaderCannabisInsider modal={modal} toggleModal={toggleModal} host={host} />}
        {(modal?.categories?.isOpen || modal?.brands?.isOpen || modal?.usages?.isOpen) && (
          <HeaderShopSectionMobile modal={modal} data={data} toggleModal={toggleModal} />
        )}
        {(modal?.dispensaries.isOpen || modal?.changeDispensary?.isOpen) && (
          <HeaderDispensaries
            data={dispensariesList}
            modal={modal}
            toggleModal={toggleModal}
            image={dispensariesImage}
            host={host}
          />
        )}
        <HeaderSearch toggleModal={toggleModal} modal={modal} />
      </HeaderBox>
      {isDispensaryDrawerOpen && (
        <DispensaryDrawer isOpen={isDispensaryDrawerOpen} setDrawerOpen={setIsDispensaryDrawerOpen} host={host} />
      )}
    </HeaderWrapper>
  );
};

export default Header;
