import React from 'react';
import { SocialIcons } from './social-icon';

type TProps = {
  socialMediaUrls: {
    facebookPage: string;
    instagramPage: string;
    twitterPage: string;
  };
};
const FooterSocialIcons = ({ socialMediaUrls }: TProps) => {
  return (
    <SocialIcons
      instagramUrl={socialMediaUrls?.instagramPage}
      twitterUrl={socialMediaUrls?.twitterPage}
      facebookUrl={socialMediaUrls?.facebookPage}
    />
  );
};
export default FooterSocialIcons;
