import { CookieButton } from './styles';
// For more info how to update button text.
// https://my.onetrust.com/s/article/UUID-2b6cc670-059c-5342-275c-4a7d8da5630e?language=en_US#UUID-2b6cc670-059c-5342-275c-4a7d8da5630e_table-5cf9595e808b6-idm45390830432672
const CookieSettingsButton = (props: any) => {
  return (
    <CookieButton id="ot-sdk-btn" {...props}>
      {/* This text will dynamically be handle in Onetrust template banner */}
      cookie settings
    </CookieButton>
  );
};

export default CookieSettingsButton;
