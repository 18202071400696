import tw from 'tailwind-styled-components';

export const HeaderCannabisInsiderMobileWrapper = tw.div`
    block 
    lg:hidden
`;

export const HeaderCannabisInsiderMobileBox = tw.div`
    flex 
    flex-row 
    items-center 
    gap-5 
    pb-5
`;
export const HeaderCannabisInsiderMobileList = tw.ul`
    [&>*:last-child_h5]:text-kief
`;

export const HeaderCannabisInsiderMobileListItem = tw.ul`
    border-b 
    border-solid 
    border-[rgba(255,255,255,0.15)] 
    py-5
`;

export const HeaderCannabisInsiderMobileListLink = tw.a`
    flex 
    flex-row 
    items-center 
    justify-between
`;
