import tw from 'tailwind-styled-components';

export const AgeGateDeniedContainer = tw.div<{ $isMedical: boolean }>`
  px-5
  flex
  flex-col
  lg:gap-10
  w-full
  h-full
  items-center
  lg:justify-center
  ${(p) => (p.$isMedical ? 'pt-12 pb-6  gap-6 xl:gap-15' : '  py-6  gap-3 xl:gap-10')}
`;

export const AgeGateDeniedInnerContainer = tw.div<{ $isMedical: boolean }>`
  w-full
  flex
  flex-col
  gap-3
  items-center
  lg:gap-14
  2xl:h-[448px]
  lg:justify-center
  ${(p) => (p.$isMedical ? 'justify-start  lg:h-[518px]' : ' h-[261px] lg:h-[554px]')}
`;

export const AgeGateDeniedImageContainer = tw.div`
  w-[188px]
  h-[161px]
  lg:w-[254px]
  lg:h-[220px]
  2xl:w-[342px]
  2xl:h-[296px]
`;

export const AgeGateDeniedHeadingContainer = tw.div<{ $isMedical: boolean }>`
  w-full
  h-[88px]
  text-center
  lg:h-[136px]
  2xl:w-full
  ${(p) => (p.$isMedical ? 'content-end lg:w-[687px]' : ' lg:w-[550px]')}
`;

export const AgeGateDeniedSubheadingContainer = tw.div<{ $isMedical: boolean }>`
  w-full
  h-16
  text-center
  lg:w-[687px]
  2xl:w-[820px]
  ${(p) => (p.$isMedical ? 'contents' : '')}
`;
