import tw from 'tailwind-styled-components';
export const HeaderShopListMobileWrapper = tw.div`
    block 
    lg:hidden
`;

export const HeaderShopListMobileIconBox = tw.div`
    flex 
    flex-row 
    items-center 
    gap-5 
    pb-5
`;

export const HeaderShopListMobileList = tw.ul`
    [&>*:first-child_h5]:text-kief
`;

export const HeaderShopListMobileListItem = tw.li`
    border-b 
    border-solid 
    border-[rgba(255,255,255,0.15)] 
    py-5
`;

export const HeaderShopListMobileListLink = tw.a`
    flex 
    flex-row 
    items-center 
    justify-between
`;
