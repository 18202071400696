/**
 * NOTE: Please use conditional import added at the end of the file to import dev tastics
 */
import dynamic from 'next/dynamic';
import { isDevelopment } from 'helpers/utils/environment';
import { TasticRegistry } from 'frontastic/lib/types';

const AboutUsHeroTastic = dynamic(() => import('./heros/about-us-hero'));
const SearchCategoryCardsTastic = dynamic(() => import('./listings/search-category-cards'));
// const BannerStatement = dynamic(() => import('components/organisms/banners/banner-statement'));

/////////////////// common so not loading them using dynamic import
import AgeGateTastic from './global/age-gate';
import CookieGateTastic from './global/cookie-gate';
import FooterComponent from './global/footer';
import GeolocationTastics from './global/geolocation';
import HeaderComponent from './global/header';
import PageSeoTastics from './global/page-seo';
import SnackBar from './global/snack-bar';
import HeroCarouselTastics from './heros/hero-carousel';
import HeroSearch from './heros/hero-search';

const HowItWorksTastic = dynamic(() => import('./rewards/how-it-works'));
const MemberBenefitsTastic = dynamic(() => import('./rewards/member-benefits'));
const RewardsMatrixTastic = dynamic(() => import('./rewards/rewards-matrix'));
const DiscountEventBanner = dynamic(() => import('./discounts/event-banner'));
const PatientRegistrationPageTastics = dynamic(() => import('./forms/patient-registration'));
const ErrorPageTastic = dynamic(() => import('./global/error-page'));
const HeroDispensaryTastic = dynamic(() => import('frontastic/tastics/heros/hero-dispensary'));
const LegalBodyTastic = dynamic(() => import('frontastic/tastics/legal/legal-body'));
const ArticleBodyTastic = dynamic(() => import('./resources/article-body'));
const WhereIsCannabisLegalTastics = dynamic(() => import('./resources/where-is-cannabis-legal'));
const StickyBarTastics = dynamic(() => import('./rewards/sticky-bar'));
const HeroArticleTastics = dynamic(() => import('./heros/hero-article'));
const HeroArticleDetailTastics = dynamic(() => import('./heros/hero-article-detail'));
const HeroContentTastics = dynamic(() => import('./heros/hero-content'));
const HeroProductListingTastic = dynamic(() => import('./heros/hero-product-listing'));
const RewardsHeroTastic = dynamic(() => import('./heros/hero-rewards'));
const ContentFeedSectionTastic = dynamic(() => import('./listings/content-feed-section'));
const DealCardsTastic = dynamic(() => import('./listings/deal-cards'));
const DispensaryNearByTastics = dynamic(() => import('./listings/dispensary-nearby'));
const OcpSectionTastics = dynamic(() => import('./listings/ocp-section'));
const ProductDetailsTastics = dynamic(() => import('./listings/product-details'));
const ProductListCards = dynamic(() => import('./listings/product-list-section'));
const QuickLinkTastic = dynamic(() => import('./listings/quick-link-cards'));
const TerpenesListTastic = dynamic(() => import('./listings/terpenes-information'));
const ArticleCardsTastic = dynamic(() => import('./media/article-cards'));
const TextWithImageTastics = dynamic(() => import('./media/text-with-image'));
const NotFound = dynamic(() => import('./not-found'));
const GeoBasedGridArticleTastic = dynamic(() => import('./resources/geo-based-grid-article'));
const SiteMap = dynamic(() => import('./site-map'));
const CarouselBrandsTastic = dynamic(() => import('./carousel/brands'));
const CarouselProductTastic = dynamic(() => import('./carousel/carousel-product'));
const ContactUsPageTastic = dynamic(() => import('./contact'));
const GroupDiscountsTastics = dynamic(() => import('frontastic/tastics/discounts/group-discounts'));
const HeroLegalTastic = dynamic(() => import('frontastic/tastics/heros/hero-legal'));
const AboutThisDispensaryTastic = dynamic(() => import('frontastic/tastics/listings/about-this-dispensary'));
const DispensaryLocationInformationTastic = dynamic(
  () => import('frontastic/tastics/listings/dispensary-location-information'),
);
const DispensaryMapTastic = dynamic(() => import('frontastic/tastics/listings/dispensary-map'));
const DispensaryNationalMapTastic = dynamic(() => import('frontastic/tastics/listings/dispensary-national-map'));
const DispensaryReviewsTastic = dynamic(() => import('frontastic/tastics/listings/dispensary-reviews'));
const DispensaryTabsTastic = dynamic(() => import('frontastic/tastics/listings/dispensary-tabs'));
const ProviderMapTastic = dynamic(() => import('frontastic/tastics/listings/provider-map'));
const StateDispensaryListTastic = dynamic(() => import('frontastic/tastics/listings/state-dispensary-list'));
const HomeDispensaryTastic = dynamic(() => import('./account/home-dispensary'));
const FAQTastic = dynamic(() => import('./banners/faq'));
const FAQTasticVariantC = dynamic(() => import('./banners/faq-variant-c'));
const MyRewardsBannerTastic = dynamic(() => import('./banners/my-rewards'));
const PrimaryDealTastics = dynamic(() => import('./banners/primary-deal'));
const PromotionalBannerTastics = dynamic(() => import('./banners/promotional-banners'));
const AboutCtaTastic = dynamic(() => import('./about-us'));
const AboutUsQuotes = dynamic(() => import('./about-us/about-us-quotes'));
const AboutUsDonationTastic = dynamic(() => import('./about-us/donation-info'));

const BlogRecipeArticleResultTastics = dynamic(() => import('./listings/blog-recipe-article-result'));
const CustomerReviewTastics = dynamic(() => import('./listings/customer-review'));
const AccountOverviewTastic = dynamic(() => import('frontastic/tastics/account/overview'));
const AccountOrderTastic = dynamic(() => import('./account/order'));
const AccountOrdersTastic = dynamic(() => import('./account/orders'));
const AccountPostCheckoutTastic = dynamic(() => import('./account/post-checkout'));
const ProfileTastic = dynamic(() => import('./account/profile'));
const MedicationInfoTastic = dynamic(() => import('./banners/medication-info'));
const SideBarTastic = dynamic(() => import('./account/sidebar'));
// WIP: enforce names which does not start with develop - GV
type NonDevKey<T extends string> = T extends 'develop${infer Rest}' ? never : T;

type ProdTastic = {
  [key: NonDevKey<string>]: React.ElementType;
};
/**
 * NOTE: production tastics ONLY
 */
const prodTastics: ProdTastic = {
  'about-us/about-us-quotes': AboutUsQuotes,
  'about-us/donation-info': AboutUsDonationTastic,
  'about/about-cta': AboutCtaTastic,
  'account/home-dispensary': HomeDispensaryTastic,
  'account/order-detail': AccountOrderTastic,
  'account/orders': AccountOrdersTastic,
  'account/overview': AccountOverviewTastic,
  'account/post-checkout': AccountPostCheckoutTastic,
  'account/profile': ProfileTastic,
  'account/sidebar': SideBarTastic,
  'banners/faq-variant-c': FAQTasticVariantC,
  'banners/medication-info-banner': MedicationInfoTastic,
  'banners/my-rewards-banner': MyRewardsBannerTastic,
  'banners/primary-deal': PrimaryDealTastics,
  'banners/promotional-banner': PromotionalBannerTastics,
  'cards/quick-link-cards': QuickLinkTastic,
  'carousel/brands': CarouselBrandsTastic,
  default: NotFound,
  'discounts/event-banner': DiscountEventBanner,
  'discounts/group-discounts': GroupDiscountsTastics,
  'forms/contact-us': ContactUsPageTastic,
  'forms/patient-registration': PatientRegistrationPageTastics,
  'global/age-gate': AgeGateTastic,
  'global/cookie-gate': CookieGateTastic,
  'global/error-page': ErrorPageTastic,
  'global/footer': FooterComponent,
  'global/geo-location': GeolocationTastics,
  'global/header': HeaderComponent,
  'global/page-seo': PageSeoTastics,
  'global/snack-bar': SnackBar,
  'heros/about-us-hero': AboutUsHeroTastic,
  'heros/hero-article': HeroArticleTastics,
  'heros/hero-article-detail': HeroArticleDetailTastics,
  'heros/hero-carousel': HeroCarouselTastics,
  'heros/hero-content': HeroContentTastics,
  'heros/hero-dispensary': HeroDispensaryTastic,
  'heros/hero-legal': HeroLegalTastic,
  'heros/hero-product-listing': HeroProductListingTastic,
  'heros/hero-rewards': RewardsHeroTastic,
  'heros/hero-search': HeroSearch,
  'legal/legal-tastic': LegalBodyTastic,
  'listings/about-this-dispensary': AboutThisDispensaryTastic,
  'listings/content-feed-section': ContentFeedSectionTastic,
  'listings/customer-review': CustomerReviewTastics,
  'listings/deal-cards': DealCardsTastic,
  'listings/dispensary-location-information': DispensaryLocationInformationTastic,
  'listings/dispensary-map': DispensaryMapTastic,
  'listings/dispensary-national-map': DispensaryNationalMapTastic,
  'listings/dispensary-nearby': DispensaryNearByTastics,
  'listings/dispensary-reviews': DispensaryReviewsTastic,
  'listings/dispensary-tabs': DispensaryTabsTastic,
  'listings/grid-articles': BlogRecipeArticleResultTastics,
  'listings/ocp-section': OcpSectionTastics,
  'listings/product-details': ProductDetailsTastics,
  'listings/product-list-section': ProductListCards,
  'listings/provider-map': ProviderMapTastic,
  'listings/search-category-cards': SearchCategoryCardsTastic,
  'listings/state-dispensary-list': StateDispensaryListTastic,
  'listings/terpenes-info': TerpenesListTastic,
  'media/article-cards': ArticleCardsTastic,
  'media/text-with-image': TextWithImageTastics,
  'resources/article-body': ArticleBodyTastic,
  'resources/geo-base-grid-article': GeoBasedGridArticleTastic,
  'resources/where-is-cannabis-legal': WhereIsCannabisLegalTastics,
  'rewards/how-it-works': HowItWorksTastic,
  'rewards/member-benefits': MemberBenefitsTastic,
  'rewards/rewards-matrix': RewardsMatrixTastic,
  'rewards/sticky-bar': StickyBarTastics,
  'rise/ui/banners/faq': FAQTastic,
  'rise/ui/carousel/carousel-product': CarouselProductTastic,
  'rise/ui/global/footer': FooterComponent,
  'rise/ui/heros/herocomponent': HeroContentTastics,
  'static/site-map': SiteMap,
};
let tastics: TasticRegistry = prodTastics;
/**
 * dev tastics ONLY
 */
if (isDevelopment()) {
  const SearchFilterTastic = dynamic(() => import('./developers/aesha'));
  const TestCard = dynamic(() => import('./developers/chitraket'));
  const TestTasticsComponent = dynamic(() => import('./developers/hardik'));
  const JerryComponent = dynamic(() => import('./developers/jerry'));
  const TestComponent = dynamic(() => import('./developers/kush'));
  const TestPage = dynamic(() => import('./developers/shakti'));

  const devTastics: TasticRegistry = {
    'developers/shakti': TestPage,
    'developers/chitraket': TestCard,
    'developers/aesha': SearchFilterTastic,
    'developers/kush': TestComponent,
    'developers/hardik': TestTasticsComponent,
    'developers/jerry': JerryComponent,
  };
  tastics = { ...prodTastics, ...devTastics };
}

export { tastics };
