import tw from 'tailwind-styled-components';

export const HeaderCannabisInsiderDesktopWrapper = tw.div`
    hidden 
    grid-cols-1 
    lg:grid 
    bg-leaf
`;

export const HeaderCannabisInsiderDesktopList = tw.ul`
    [&>*:last-child_a]:text-kief 
    [&_a]:text-white
`;
