import { useEffect, useState } from 'react';
import { SnackBarComponent } from 'components/organisms/global/snack-bar';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { ICookieConfig } from 'helpers/types';
import { COMMON_COOKIES } from 'helpers/types/cookies';
import { getCurrentStateSnackbarCookieValue, setSessionCookie } from 'helpers/utils/cookies';

const cookieConfig: ICookieConfig = {
  name: '_rise_snack_bar_status',
  possible_values: {
    closed: 'closed',
    opened: 'opened',
  },
  max_days: null,
};

type TProps = {
  data: any;
  pageFolder: any;
};

const SnackBar = ({ data, pageFolder }: TProps) => {
  const content = data?.snackBar?.dataSource?.data?.pageStateCollection?.items[0]?.stickyBarCollection?.items[0];
  const snackBarCookies = pageFolder?.cookies?.stickyBarCookies;
  const geoState = pageFolder?.cookies?.stateID;

  const [showSnackBar, setShowSnackBar] = useState(!getCurrentStateSnackbarCookieValue(snackBarCookies, geoState));
  const handleCloseAction = (sendTrack: boolean) => {
    setShowSnackBar(false);
    setSessionCookie(
      COMMON_COOKIES.STICKY_BAR,
      `${decodeURIComponent(snackBarCookies) !== 'null' ? decodeURIComponent(snackBarCookies) : ''}${geoState}=${
        cookieConfig.possible_values.closed
      };`,
    );
    document.getElementById('rise-header').classList.remove('visible-snack-bar-header');
    document.getElementById('mainContent').classList.remove('visible-snack-bar-main-content');

    if (sendTrack) {
      const bannerClickedData = {
        placement: 1,
        banner_position: 1,
        banner_internal_name: content?.internalName || '',
        banner_title: content?.title || '',
        banner_image_main: '',
        banner_type: 'Snackbar',
        link_name: content?.cta?.title || '',
        link_type: 'hyperlink',
      };
      bannerTrack(SegmentEvents.BANNER_CLOSED, bannerClickedData);
    }
  };

  useEffect(() => {
    if (!content) {
      handleCloseAction(false);
    }
  }, [content]);

  return (
    (content?.title || content?.cta?.title) && (
      <SnackBarComponent
        title={content?.title}
        cta={content?.cta}
        backgroundColor="kiefGreen"
        showSnackBar={showSnackBar}
        handleCloseAction={() => handleCloseAction(true)}
        host={pageFolder?.host}
      />
    )
  );
};

export default SnackBar;
