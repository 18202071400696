import { SegmentEvents } from '../constants/forms';
import { ICommunicationInitiated, SegementTrackEvent } from './segment-helper';

export const communicationInitiated = (value) => {
  const data: ICommunicationInitiated = {
    ...value,
  };

  SegementTrackEvent(SegmentEvents.COMMUNICATION_INITIATED, data, { sendGeoData: true });
};
