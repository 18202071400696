import tw from 'tailwind-styled-components';
type TProps = {
  $isSelected?: boolean;
  $isOpen?: boolean;
};

export const OuterContainer = tw.div<TProps>`
    flex 
    gap-4
`;

export const Container = tw.div`
    flex-col 
    items-start
`;

export const List = tw.div`
    flex
    absolute
    max-w-full
    grow
    flex-col
    items-start
    border
    border-solid
    border-grey-400
    bg-grey-100
    rounded-b-3xl
    z-[1]
    `;

export const ListHeader = tw.div`
    flex
    w-full
    items-start 
    justify-between 
    gap-2.5 
    self-stretch 
    bg-grey-100 
    p-5
`;

export const ListContainer = tw.div<TProps>`
    bg-stone-100 
    flex 
    w-full
    self-stretch
    px-6 
    py-[22px]
    items-center
    ${({ $isSelected }) => ($isSelected ? 'justify-between gap-2' : '  ')}      
`;

export const ListItem = tw.div<TProps>`
    leading-5 
    tracking-normal
    self-center 
    text-base
    ${({ $isSelected }) => ($isSelected ? 'text-grey-500 my-auto font-bold' : `text-grey-400 w-[106px] max-w-full`)}
`;

export const MobileListItem = tw.div<TProps>`
    w-full 
    flex
    items-center 
    self-stretch 
    border-b 
    border-solid 
    border-b-[color:var(--secondary-grey-2,#E6EAD6)] 
    bg-white 
    p-5 
    ${({ $isSelected }) => ($isSelected ? 'justify-between gap-2' : ``)}`;
