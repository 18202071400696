import { SegmentEvents } from '../constants/forms';
import { IAgeGateTrackEventProps, SegementTrackEvent } from './segment-helper';

export const ageGateConsentedTrack = ({
  terms_of_service,
  privacy_policy,
  notice_of_privacy_practices,
  response,
}: IAgeGateTrackEventProps) => {
  const eventData: IAgeGateTrackEventProps = {
    terms_of_service,
    privacy_policy,
    notice_of_privacy_practices,
    response,
  };

  SegementTrackEvent(SegmentEvents.AGE_CONSENTED, eventData, { sendGeoData: true });
};
