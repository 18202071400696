import { Dispatch, SetStateAction } from 'react';
import { Formik } from 'formik';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { FormikSelect } from 'components/molecules/form-fields/select';
import {
  TValidateBirthdayInputProps,
  getAllDaysPerMonth,
  getAllMonths,
  getYearFormValues,
  validateBirthday,
} from './age-gate-helpers';
import {
  AgeGateAlternativeVerificationFormContainer,
  AgeGateAlternativeVerificationFormFieldContainer,
  AgeGateAlternativeVerificationFormFieldsContainer,
} from './alternative-verification-styles';

type TAlternativeVerificationFormProps = {
  setAgeGateVisibilityCallback: Dispatch<SetStateAction<boolean>>;
  setDeniedVisibilityCallback: Dispatch<SetStateAction<boolean>>;
  onInvalidBirthdayCallback: () => void;
  onValidBirthdayCallback: () => void;
  onValidAgeForMedical: () => void;
};

type TBirthday = {
  birthMonth: string;
  birthDay: string;
  birthYear: string;
};

export const AgeGateAlternativeVerificationFormComponent = ({
  setAgeGateVisibilityCallback,
  setDeniedVisibilityCallback,
  onInvalidBirthdayCallback,
  onValidBirthdayCallback,
  onValidAgeForMedical,
}: TAlternativeVerificationFormProps) => {
  const calendarMonths = getAllMonths();

  const isSubmitDisabled = ({ birthMonth, birthDay, birthYear }: TBirthday) => {
    if (birthMonth !== undefined && birthMonth !== null && birthDay && birthYear) {
      return false;
    }
    return true;
  };

  return (
    <AgeGateAlternativeVerificationFormContainer data-testid="age-gate-alt-form-container">
      <Typography variant="h5" className="text-center uppercase" data-testid="birthdayText">
        please enter your birthday
      </Typography>
      <Formik
        initialValues={{
          birthMonth: '',
          birthDay: '',
          birthYear: '',
        }}
        onSubmit={(formSubmission) => {
          const { birthMonth, birthDay, birthYear } = formSubmission;

          const data = {
            birthMonth: Number(birthMonth),
            birthDay: Number(birthDay),
            birthYear: Number(birthYear),
            alternativeAgeOfVerification: true,
          } as TValidateBirthdayInputProps;

          const isValidBirthday = validateBirthday(data);
          if (isValidBirthday.isMedical) {
            setAgeGateVisibilityCallback(true);
            onValidAgeForMedical();
          } else if (isValidBirthday.isValid) {
            onValidBirthdayCallback();
          } else {
            setDeniedVisibilityCallback(true);
            onInvalidBirthdayCallback();
          }
        }}
      >
        {(formikProps) => {
          return (
            <>
              <AgeGateAlternativeVerificationFormFieldsContainer data-testid="age-gate-alt-form-fields-container">
                <AgeGateAlternativeVerificationFormFieldContainer data-testid="age-gate-birthday-month-dropdown">
                  <FormikSelect
                    {...formikProps}
                    label="Month"
                    fieldName="birthMonth"
                    items={calendarMonths}
                    required={true}
                  />
                </AgeGateAlternativeVerificationFormFieldContainer>
                <AgeGateAlternativeVerificationFormFieldContainer data-testid="age-gate-birthday-day-dropdown">
                  <FormikSelect
                    {...formikProps}
                    label="Day"
                    fieldName="birthDay"
                    items={getAllDaysPerMonth(formikProps?.values?.birthMonth, formikProps?.values?.birthYear)}
                    required={true}
                  />
                </AgeGateAlternativeVerificationFormFieldContainer>
                <AgeGateAlternativeVerificationFormFieldContainer data-testid="age-gate-birthday-year-dropdown">
                  <FormikSelect
                    {...formikProps}
                    label="Year"
                    fieldName="birthYear"
                    items={getYearFormValues()}
                    required={true}
                  />
                </AgeGateAlternativeVerificationFormFieldContainer>
              </AgeGateAlternativeVerificationFormFieldsContainer>
              <CommonButton
                button-type="primary"
                color="green"
                className="w-full"
                onClick={() => formikProps.submitForm()}
                disabled={isSubmitDisabled(formikProps.values)}
                data-testid="age-gate-alt-form-submit-btn"
              >
                Submit
              </CommonButton>
            </>
          );
        }}
      </Formik>
    </AgeGateAlternativeVerificationFormContainer>
  );
};
