import { FunctionComponent } from 'react';
import CookieSettingsButton from 'components/atoms/buttons/cookie-settings';
import { Typography } from 'components/atoms/typography';
import CustomerCenterDrawer from 'components/organisms/drawers/customer-center';
import { ReplaceUrlWithHost, getLinkTarget } from 'helpers/utils/common';
import ExternalLinkIcon from 'public/icons/figma/external-link.svg';
import { FooterTocContainer, FooterTocMenuItem } from './styles';

type FooterTocMenuProps = {
  tocNavigationMenu: Array<{
    navigationLabel: string;
    navigationUrl: string;
  }>;
  host: string;
  customerCenterData: {
    ccHeading: string;
    ccDescription: string;
    ccLogo: {
      mobileImage: any;
      desktopImage: {
        sys: {
          id: string;
        };
        title: string;
        url: string;
        description: any;
        width: number;
        height: number;
      };
    };
    ccOptionsCollection: {
      items: {
        title: string;
        description: string;
        url: string;
        icon: {
          sys: {
            id: string;
          };
          title: string;
          url: string;
          description: any;
          width: number;
          height: number;
        };
      }[];
    };
  };
};

export const FooterTocMenu: FunctionComponent<FooterTocMenuProps> = ({
  tocNavigationMenu,
  customerCenterData,
  host,
}) => {
  return (
    <FooterTocContainer>
      {Array.isArray(tocNavigationMenu) &&
        tocNavigationMenu
          ?.filter((item) => item?.navigationLabel && item?.navigationUrl)
          ?.map((menuItem, index) => {
            const isExternal = menuItem.navigationUrl.includes('https');
            const isCenterLink = menuItem.navigationLabel.toLowerCase().includes('center');

            return isCenterLink ? (
              <FooterTocMenuItem key={index}>
                <CustomerCenterDrawer label={menuItem.navigationLabel} {...customerCenterData} />
              </FooterTocMenuItem>
            ) : (
              <FooterTocMenuItem key={index}>
                <Typography
                  variant="body-small"
                  as="a"
                  target={getLinkTarget(ReplaceUrlWithHost(menuItem?.navigationUrl, host) || '/', isExternal)}
                  title={menuItem?.navigationLabel}
                  className="flex items-center !text-grey-400 hover:!text-leaf"
                  href={ReplaceUrlWithHost(menuItem?.navigationUrl, host) || '/'}
                >
                  {menuItem?.navigationLabel}
                  {isExternal && <ExternalLinkIcon className="ml-2" />}
                </Typography>
              </FooterTocMenuItem>
            );
          })}
      <FooterTocMenuItem>
        <CookieSettingsButton />
      </FooterTocMenuItem>
    </FooterTocContainer>
  );
};
