import tw from 'tailwind-styled-components';
export const HeaderShopListDesktopWrapper = tw.div`
    hidden
    grid-cols-10
    lg:grid
`;

export const HeaderShopListDesktopList = tw.ul`
    lg:col-start-1
    lg:col-end-5
    [&>*:first-child_a]:text-kief
    [&_a]:text-white
`;
