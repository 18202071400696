export enum THeaderState {
  NAVBAR = 'navbar',
  SHOP = 'shop',
  CATEGORIES = 'categories',
  BRANDS = 'brands',
  USAGES = 'usages',
  DISPENSARIES = 'dispensaries',
  CHANGEDDISPENSARY = 'changeDispensary',
  CANNABISINSIDER = 'cannabisInsider',
  SEARCHBAR = 'searchBar',
  RESOURCES = 'resources',
}

export enum THeaderShopUrlEnums {
  DEAL = '#state-deals',
  CATEGORIES = '#categories',
  BRANDS = '#brands',
  USAGES = '#usages',
  MENU = '#menu',
}

export type THeaderVariantTypes = 'light' | 'dark';
