import tw from 'tailwind-styled-components';
export const HeaderStateDesktopWrapper = tw.div`
    hidden 
    grid-cols-3 
    lg:grid
`;

export const HeaderStateDesktopList = tw.ul`
    grid 
    col-span-2  
    grid-flow-col 
    gap-x-6 
    gap-y-4 
    [grid-template-rows:repeat(7,minmax(0,1fr))]
`;

export const HeaderStateDesktopImageBox = tw.div`
    relative 
    flex 
    h-[360px] 
    justify-end
`;
