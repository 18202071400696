import tw from 'tailwind-styled-components';
export const HeaderNavBarMobileWrapper = tw.div`
    block 
    bg-leaf-dark 
    px-5 
    py-6 
    lg:hidden
`;

export const HeaderNavBarMobileBox = tw.ul`
    flex 
    flex-col 
    gap-5
`;
export const HeaderNavBarMobileLink = tw.a`
    flex 
    w-full 
    flex-row 
    items-center 
    justify-between
`;
