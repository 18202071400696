import React from 'react';
import { TContenfulImageProp } from 'helpers/types';
import { TInitialStateProps } from '..';
import HeaderChangeDispensary from './header-change-dispensary';
import HeaderState from './header-state';
import { HeaderDispensariesWrapper } from './styles';

type TProps = {
  toggleModal: (name: string, data?: any) => void;
  modal: TInitialStateProps;
  data: any;
  image: TContenfulImageProp;
  host: string;
};

const HeaderDispensaries = ({ data, modal, toggleModal, image, host }: TProps) => {
  return (
    <HeaderDispensariesWrapper data-testid="header-dispensaries" className="openMenuAnimation">
      {modal?.dispensaries?.isOpen && <HeaderState data={data} toggleModal={toggleModal} image={image} />}
      {modal?.changeDispensary?.isOpen && (
        <HeaderChangeDispensary
          data={modal?.changeDispensary?.data}
          toggleModal={toggleModal}
          image={image}
          host={host}
        />
      )}
    </HeaderDispensariesWrapper>
  );
};

export default HeaderDispensaries;
