import tw from 'tailwind-styled-components';
type TProps = {
  $isDob?: boolean;
};
export const AgeGateOuterContainer = tw.div`
  w-full
  h-full
  max-h-full
  max-w-screen
  fixed
  top-0
  z-[2147483647]
`;

export const AgeGateContainer = tw.div`
  relative
  w-full
  h-full
  max-h-full
  max-w-screen
  flex 
  flex-col 
  items-start
  lg:flex-row
  bg-white
  overflow-hidden
  z-[2147483647]
`;

export const AgeGateWelcomeContainer = tw.div<TProps>`
  bg-kief
  w-full
  h-full 
  max-h-[164px]
  lg:max-h-full
  py-3
  flex
  justify-center
  lg:min-w-[280px]
  lg:max-w-[513px]
  2xl:justify-start
  2xl:w-[516px]
  flex
  lg:flex-col
  gap-3
  items-center
  lg:gap-20
  lg:py-[60px]
  lg:px-10
  2xl:pt-[188px]
  2xl:pb-0
  2xl:px-[80px]
  ${(p) => (p.$isDob ? ' max-h-[72px] flex-row' : ' max-h-[164px] flex-col ')}
`;

export const AgeGateWelcomeLogo = tw.div`
  w-[50px]
  h-[80px]
  md:hidden
`;

export const AgeGateWelcomeLogoTablet = tw.div`
  hidden
  md:flex
  w-[99px]
  h-[150px]
  2xl:w-[116px]
  2xl:h-[176px]
`;

export const AgeGateWelcomeTextContainer = tw.div`
  w-[190px]
  md:w-[250px]
  h-12
  lg:w-full
`;

export const AgeGateBodyContainer = tw.div`
  relative
  flex
  justify-center
  w-full
  h-full
  max-w-screen
  min-h-full
  mt-0
  lg:min-w-[767px]
  lg:px-10
  xl:px-20
  overflow-y-scroll
`;

export const AgeGateBackgroundVerificationAgeContainer = tw.p<TProps>`
  absolute
  w-[287px]
  h-[240px]
  lg:h-[368px]
  lg:w-[440px]
  xl:h-[448px]
  xl:w-[535px]
  z-[-1]
  lg:!top-[105px]
  xl:!top-[136px]
  lg:left-[35%]
  lg:text-[368px]
  lg:leading-[368px]
  flex
  items-center
  font-antarctican 
  text-[240px] 
  font-bold 
  tracking-[2.4px] 
  text-kief 
  lg:text-[368px] 
  2xl:text-[448px]
`;

export const AgeGateInnerBodyContainer = tw.div<TProps>`
  relative
  flex
  flex-col
  items-center 
  self-stretch  
  w-full
  2xl:w-full

 ${(p) =>
   p.$isDob
     ? 'pt-[77px] lg:gap-[80px] xl:gap-[120px] xl:mt-[215px] 2xl:mt-[270px]'
     : ' gap-12 pt-[88px] lg:gap-[120px] xl:gap-[224px] xl:mt-[215px]'}

  px-5
  pb-6
  lg:px-0
  lg:pb-20
  lg:pt-[135px]
  mt-8
  lg:mt-[140px]
`;

export const AgeGateVerificationContainer = tw.div<TProps>`
  flex 
  flex-col
  gap-6 
  text-center
  h-auto
  w-full
  lg:max-w-[687px]
  xl:max-w-[100%]
  ${(p) => (p.$isDob ? 'lg:gap-10 xl:gap-[60px]' : ' lg:gap-10 ')}
`;

export const AgeGateTermsContainer = tw.div<TProps>`
  w-full
  xl:w-[670px]
  //h-[184px]
  h-auto

  flex
  flex-col
  gap-5
  2xl:gap-[72px]
  2xl:h-[300px]
  items-center
  ${(p) => (p.$isDob ? 'mt-4 gap-4 lg:mt-0 lg:gap-[42px]' : 'mt-0   lg:gap-[48px]')}
`;

export const AgeGateTermsButtonRow = tw.div`
  flex
  flex-row
  w-full
  justify-center
  gap-2.5
`;

export const AgeGateTermsButtonContainer = tw.div`
  w-[150px]
  lg:w-[150px]
  lg:h-[88px]
  h-10
  2xl:w-[225px]
  2xl:h-32
`;

export const AgeGateButtonStyleOverrides = `
  h-full
  w-full
  !rounded-[102px]
  lg:!rounded-[200px]
  !font-arial
  !text-sm 
  !not-italic 
  !font-bold 
  !leading-4 
  !tracking-[0.14px]
  uppercase
  2xl:!text-[42px]
  2xl:!font-medium 
  2xl:!leading-10 
  2xl:!tracking-[0.84px]
  2xl:!font-antarctican
  lg:!text-[32px] 
  lg:!font-medium 
  lg:!leading-[30px] 
  lg:!tracking-[0.64px]
  lg:!font-antarctican
  lg:!px-[28px]
  lg:!py-[18px]
  xl:!px-[38px]
  xl:!py-[22px]
`;
