import { useEffect, useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { ageGateConsentedTrack } from 'helpers/analytics/age-gate-consented-track';
import { AGE_GATE_COOKIE_NAME } from 'helpers/constants/cookies';
import { TAgeGateProps } from 'helpers/types';
import { setCookie } from 'helpers/utils/cookies';
import { AgeGateAlternativeVerificationFormComponent } from './alternative-verification-form';
import { AgeGateDeniedComponent } from './gate-denied';
import {
  AgeGateBackgroundVerificationAgeContainer,
  AgeGateBodyContainer,
  AgeGateButtonStyleOverrides,
  AgeGateContainer,
  AgeGateInnerBodyContainer,
  AgeGateOuterContainer,
  AgeGateTermsButtonContainer,
  AgeGateTermsButtonRow,
  AgeGateTermsContainer,
  AgeGateVerificationContainer,
  AgeGateWelcomeContainer,
  AgeGateWelcomeLogo,
  AgeGateWelcomeLogoTablet,
  AgeGateWelcomeTextContainer,
} from './styles';

export const AgeGateComponent = ({
  showModal,
  companyLogoDesktopSrc,
  companyLogoSrc,
  welcomeText,
  ageOfVerificationHeading,
  ageOfVerificationAltHeading,
  ageOfVerificationTerms,
  showAlternativeAgeVerification,
  deniedImageSrc,
  deniedHeading,
  deniedSubheading,
  ageLimit,
  additionalQuestion,
  under21Title,
  under21Description,
  under21CtaTitle,
}: TAgeGateProps) => {
  const [showAgeGate, setAgeGateVisibility] = useState(showModal);
  const [showDeniedState, setDeniedVisibility] = useState(false);
  const [showMedical, setShowMedical] = useState(false);

  useEffect(() => {
    setAgeGateVisibility(showModal);
  }, [showModal]);

  // Also runs when invalid birthday is submitted
  const onDenied = () => {
    setDeniedVisibility(true);
    ageGateConsentedTrack({
      terms_of_service: false,
      privacy_policy: false,
      notice_of_privacy_practices: false,
      response: false,
    });
  };
  // Also runs when valid birthday is submitted
  const onAccepted = () => {
    setAgeGateVisibility(false);
    setCookie(AGE_GATE_COOKIE_NAME, 'true', 1);

    ageGateConsentedTrack({
      terms_of_service: true,
      privacy_policy: true,
      notice_of_privacy_practices: true,
      response: true,
    });
  };

  return (
    showAgeGate && (
      <AgeGateOuterContainer data-testid="age-gate-outer-container">
        <AgeGateContainer data-testid="age-gate-container">
          <AgeGateWelcomeContainer data-testid="age-gate-welcome-bg" $isDob={showAlternativeAgeVerification}>
            {/* <AgeGateInnerWelcomeContainer> */}
            <AgeGateWelcomeLogo
              style={{
                backgroundImage: `${
                  companyLogoSrc ? `url(${companyLogoSrc?.url})` : `url(${companyLogoDesktopSrc?.url})`
                }`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
              data-testid="age-gate-welcome-logo"
            />
            <AgeGateWelcomeLogoTablet
              style={{
                backgroundImage: `${companyLogoDesktopSrc ? `url(${companyLogoDesktopSrc?.url})` : 'unset'}`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                display: 'hidden',
              }}
              data-testid="age-gate-welcome-logo-desktop"
            />
            <AgeGateWelcomeTextContainer>
              <Typography variant="h4" className="text-center" data-testid="age-gate-welcome-text">
                {welcomeText}
              </Typography>
            </AgeGateWelcomeTextContainer>
            {/* </AgeGateInnerWelcomeContainer> */}
          </AgeGateWelcomeContainer>
          {!(showDeniedState || showMedical) && (
            <AgeGateBodyContainer>
              <AgeGateBackgroundVerificationAgeContainer
                data-testid="age-gate-bg-verification-age"
                $isDob={showAlternativeAgeVerification}
              >
                {ageLimit}
              </AgeGateBackgroundVerificationAgeContainer>
              <AgeGateInnerBodyContainer $isDob={showAlternativeAgeVerification}>
                <AgeGateVerificationContainer $isDob={showAlternativeAgeVerification}>
                  <Typography variant="h2" className="xl:text-[76px] " data-testid="age-gate-verification-question">
                    {ageOfVerificationAltHeading && showAlternativeAgeVerification
                      ? ageOfVerificationAltHeading
                      : ageOfVerificationHeading}
                  </Typography>

                  {additionalQuestion && showAlternativeAgeVerification && (
                    <Typography variant="h4" data-testid="age-gate-verification-additional-question">
                      {additionalQuestion}
                    </Typography>
                  )}
                </AgeGateVerificationContainer>
                <AgeGateTermsContainer $isDob={showAlternativeAgeVerification}>
                  <div data-testid="age-gate-terms">
                    <ContentfulRichText
                      data={ageOfVerificationTerms}
                      renderNodeOptions={{
                        [BLOCKS.PARAGRAPH]: (node, children) => {
                          return (
                            <Typography
                              variant="body-small"
                              className="text-center !text-grey-400 lg:pr-0 xl:pl-0 2xl:pl-0 [&_a]:text-[16px] [&_a]:text-grey-500"
                            >
                              {children}
                            </Typography>
                          );
                        },
                      }}
                    />
                  </div>
                  {showAlternativeAgeVerification ? (
                    <AgeGateAlternativeVerificationFormComponent
                      setAgeGateVisibilityCallback={setAgeGateVisibility}
                      setDeniedVisibilityCallback={setDeniedVisibility}
                      onInvalidBirthdayCallback={onDenied}
                      onValidBirthdayCallback={onAccepted}
                      onValidAgeForMedical={() => setShowMedical(true)}
                    />
                  ) : (
                    <AgeGateTermsButtonRow>
                      <AgeGateTermsButtonContainer>
                        <CommonButton
                          size="sm"
                          color="green"
                          button-type="primary"
                          className={AgeGateButtonStyleOverrides}
                          onClick={() => {
                            onAccepted();
                          }}
                          data-testid="age-gate-yes-button"
                        >
                          Yes
                        </CommonButton>
                      </AgeGateTermsButtonContainer>
                      <AgeGateTermsButtonContainer>
                        <CommonButton
                          size="sm"
                          color="green"
                          button-type="primary"
                          className={AgeGateButtonStyleOverrides}
                          onClick={() => {
                            onDenied();
                          }}
                          data-testid="age-gate-no-button"
                        >
                          No
                        </CommonButton>
                      </AgeGateTermsButtonContainer>
                    </AgeGateTermsButtonRow>
                  )}
                </AgeGateTermsContainer>
              </AgeGateInnerBodyContainer>
            </AgeGateBodyContainer>
          )}
          {(showDeniedState || showMedical) && (
            <AgeGateDeniedComponent
              deniedHeading={deniedHeading}
              deniedImageSrc={deniedImageSrc}
              deniedSubheading={deniedSubheading}
              under21Title={under21Title}
              under21Description={under21Description}
              under21CtaTitle={under21CtaTitle}
              isOpen={showMedical}
              onClick={() => {
                onAccepted();
              }}
            />
          )}
        </AgeGateContainer>
      </AgeGateOuterContainer>
    )
  );
};
