import tw from 'tailwind-styled-components';
type THeaderImageBoxProps = {
  $h: string;
};

export const HeaderImageAnchor = tw.a`
    flex
    items-center  
    justify-center
`;

export const HeaderImageBox = tw.div<THeaderImageBoxProps>`
    relative
    h-[48px]
    w-[40px]
    md:w-[64px]
    md:h-[60px]
    overflow-hidden
    cursor-pointer
    [&_img]:object-contain
`;
