import tw from 'tailwind-styled-components';
import { TProps, Typography } from 'components/atoms/typography';
interface SliderTextProps extends TProps {
  $isDark?: boolean;
}

const isDark = (p: any) => (p.$isDark ? 'text-light' : 'text-leaf');

export const SliderDescriptionText = tw(Typography)<SliderTextProps>`
  text-center
  ${isDark}
`;

export const SliderTitleText = tw(SliderDescriptionText)`
  lg:text-left
`;

export const HomeSliderContainer: any = tw.div`
  w-full
  h-[684px]
  md:h-[848px]
  relative
`;

export const SliderCardDetailsContainer: any = tw.div`
  absolute
  w-full
  bottom-10
  lg:bottom-0
`;

export const SliderCardContainer: any = tw.div`
  flex
  flex-col
  justify-center
  items-center
  lg:grid
  lg:grid-cols-12
  lg:w-auto
  lg:h-auto
  lg:ml-[60px]
  lg:mb-14
`;

export const SliderCardTitleWrapper: any = tw.div<{ $textHeading: string }>`
  flex
  justify-center
  items-center
  mb-4
  px-[36.5px]
  lg:px-0
  lg:col-span-5
  ${(props) => props.$textHeading && props.$textHeading}`;

export const DescriptionButtonContainer: any = tw.div`
  flex
  flex-col
  justify-center
  items-center
  lg:col-span-5
  lg:flex
  lg:flex-col
  lg:items-center
  lg:justify-center
  lg:pt-[98px]
`;

export const SliderCardDescriptionWrapper: any = tw.div<{ $textDescription: string }>`
  px-[26px]
  mb-4
  lg:mb-8
  ${(props) => props.$textDescription && props.$textDescription}
  `;

export const ButtonContainer: any = tw.div`
  lg:flex
  lg:w-auto
  lg:justify-center
  lg:items-center
`;

export const ButtonWraper: any = tw.div<{ $buttonText: string }>`
  flex
  justify-center
  items-center
  mb-4
  lg:mb-0
`;

export const PaginationContainer: any = tw.div`
  lg:w-auto
  lg:ml-4
  lg:px-0
  h-6
`;

export const OverlayContainer = tw.div<{ $chooseDarkTemplate: boolean }>`
  absolute
  bottom-0
  h-full
  w-full
  bg-gradient-to-t
  ${(props) =>
    props.$chooseDarkTemplate ? 'from-dark to-transparent opacity-80' : 'from-light to-transparent opacity-60'}
`;
