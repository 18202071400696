import { BLOCKS } from '@contentful/rich-text-types';
import { ButtonType, CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { IContentfulImage } from 'helpers/types/common.type';
import {
  ModalCenteredBodyButtonSection,
  ModalCenteredBodyCloseIcon,
  ModalCenteredBodyContainer,
  ModalCenteredBodyContentSection,
  ModalCenteredBodyContentTextSection,
  ModalCenteredBodyImage,
  ModalCenteredBodyInnerContainer,
  ModalCenteredBodyInnerContent,
} from './styles';
export interface ModalCenteredBodyProps {
  image?: IContentfulImage;
  title: string;
  content?: any;
  buttonCloseLabel?: string;
  buttonCtaLabel?: string;
  onClose?: () => any;
  onClick?: () => any;
  ctaLink?: string;
  closeModalOnExitClick?: boolean;
  reverseButtonOrder?: boolean;
  cancelButtonType?: ButtonType;
  ctaButtonType?: ButtonType;
  hideModalCta?: boolean;
}

const ModalCenteredBody = ({
  image = { url: '', title: '', alt: '' },
  title = '',
  content,
  onClose,
  buttonCloseLabel = 'Cancel',
  buttonCtaLabel = 'Yes',
  ctaLink,
  onClick,
  closeModalOnExitClick = false,
  reverseButtonOrder = false,
  cancelButtonType,
  ctaButtonType,
  hideModalCta = false,
}: ModalCenteredBodyProps) => {
  const onModalClose = () => onClose?.();
  const onModalSubmit = () => {
    onClick?.();
    if (closeModalOnExitClick) onModalClose();
  };
  return (
    <ModalCenteredBodyContainer>
      <ModalCenteredBodyInnerContainer>
        <ModalCenteredBodyCloseIcon onClick={onModalClose} />
        <ModalCenteredBodyInnerContent>
          {image?.url && (
            <ModalCenteredBodyImage
              src={image?.url}
              alt={image?.alt}
              height={image?.height || 50}
              width={image?.width || 50}
              ariaLabel={image?.title}
              layout="intrinsic"
              loader={(options) => options?.src}
            />
          )}
          <ModalCenteredBodyContentSection>
            <Typography variant="h3" className="text-center lg:w-[464px]">
              {title}
            </Typography>
            {content && (
              <ModalCenteredBodyContentTextSection>
                <ContentfulRichText
                  data={content}
                  renderNodeOptions={{
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <Typography variant="body-small" className="!text-center !text-grey-400">
                        {children}
                      </Typography>
                    ),
                  }}
                />
              </ModalCenteredBodyContentTextSection>
            )}
          </ModalCenteredBodyContentSection>
        </ModalCenteredBodyInnerContent>
        <ModalCenteredBodyButtonSection className={`${reverseButtonOrder ? 'flex-row-reverse' : ''}`}>
          <CommonButton button-type={cancelButtonType || 'secondary'} color="green" onClick={onModalClose}>
            {buttonCloseLabel}
          </CommonButton>
          {!hideModalCta &&
            (ctaLink ? (
              <CommonButton button-type={ctaButtonType || 'primary'} color="green" as="a" href={ctaLink}>
                {buttonCtaLabel}
              </CommonButton>
            ) : (
              <CommonButton button-type={ctaButtonType || 'primary'} color="green" onClick={onModalSubmit}>
                {buttonCtaLabel}
              </CommonButton>
            ))}
        </ModalCenteredBodyButtonSection>
      </ModalCenteredBodyInnerContainer>
    </ModalCenteredBodyContainer>
  );
};

export default ModalCenteredBody;
