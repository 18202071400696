import { useSelector } from 'react-redux';
import { AgeGateComponent } from 'components/organisms/global/age-gate';
import { AGE_GATE_COOKIE_NAME } from 'helpers/constants/cookies';
import { IContentfulImage, IRichTextData } from 'helpers/types';
import { getCookie } from 'helpers/utils/cookies';
import { RootState } from 'redux/store';

type TAgeGateTasticProps = {
  data: {
    hideAgeGate: boolean;
    ageGateData: {
      dataSource: {
        data: {
          popupAgeGateCollection: {
            items: [
              {
                ageLimit: string;
                ageGateBanner: {
                  title: string;
                  overlayImage: {
                    desktopImage: IContentfulImage;
                    mobileImage: IContentfulImage;
                  };
                };
                ageVerificationQuestion: string;
                ageVerificationAdditionalQuestion: string;
                underAgeAcknowledgement: {
                  title: string;
                  description: IRichTextData;
                  mobileImage: {
                    url: string;
                  };
                };
                under21Acknowledgement: {
                  title: string;
                  description: IRichTextData;
                  ctaCollection: {
                    items: [
                      {
                        title: string;
                      },
                    ];
                  };
                };
                enableDobForm: boolean;
                ageGatePrivacyText: {
                  json: string;
                };
              },
            ];
          };
        };
      };
    };
  };
  pageFolder: Record<string, any>;
};

const AgeGateTastic = ({ data, pageFolder }: TAgeGateTasticProps) => {
  const ageGateHidden = useSelector((state: RootState) => state?.ageGate?.isHidden);
  if (!data || !data?.ageGateData || !data?.ageGateData?.dataSource?.data) {
    return <></>;
  }
  const ageGateCookie = pageFolder && pageFolder?.cookies?.ageGate;
  const responseValidatedCookie = getCookie(AGE_GATE_COOKIE_NAME);
  const ageGateCookieExists = !((ageGateCookie && ageGateCookie === 'true') || ageGateHidden
    ? ageGateHidden === 'true'
    : data.hideAgeGate);
  const showModal = responseValidatedCookie ? !responseValidatedCookie : ageGateCookieExists;
  const { popupAgeGateCollection } = data?.ageGateData?.dataSource?.data ?? {};
  const {
    ageLimit,
    ageGateBanner,
    ageVerificationQuestion,
    underAgeAcknowledgement,
    enableDobForm,
    ageGatePrivacyText,
    ageVerificationAdditionalQuestion,
    under21Acknowledgement,
  } = popupAgeGateCollection?.items?.[0] || {};
  return (
    <AgeGateComponent
      showModal={showModal}
      companyLogoDesktopSrc={ageGateBanner?.overlayImage?.desktopImage}
      companyLogoSrc={ageGateBanner?.overlayImage?.mobileImage}
      welcomeText={ageGateBanner?.title}
      ageOfVerificationHeading={ageVerificationQuestion}
      ageOfVerificationAltHeading={ageVerificationQuestion}
      ageOfVerificationTerms={ageGatePrivacyText}
      showAlternativeAgeVerification={enableDobForm}
      deniedImageSrc={underAgeAcknowledgement?.mobileImage}
      deniedHeading={underAgeAcknowledgement?.title}
      deniedSubheading={underAgeAcknowledgement?.description}
      ageLimit={ageLimit}
      additionalQuestion={ageVerificationAdditionalQuestion}
      under21Title={under21Acknowledgement?.title}
      under21Description={under21Acknowledgement?.description}
      under21CtaTitle={under21Acknowledgement?.ctaCollection?.items[0]?.title}
    />
  );
};

export default AgeGateTastic;
