import tw from 'tailwind-styled-components';

export const HeaderStateMobileWrapper = tw.div`
    block 
    lg:hidden
`;

export const HeaderStateMobileButtonBox = tw.div`
    flex 
    flex-row 
    items-center 
    gap-5 
    pb-5
    px-5
`;

export const HeaderStateMobileList = tw.ul`
    h-[425px] 
    overflow-y-scroll 
    px-5
`;

export const HeaderStateMobileListItem = tw.li`
    border-b 
    border-solid 
    border-[rgba(255,255,255,0.15)] 
    py-5
`;

export const HeaderStateMobileListLink = tw.a`
    flex 
    flex-row 
    items-center 
    justify-between 
    text-white
`;
