import { useState } from 'react';
import Image from 'next/image';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import Drawer from 'components/atoms/drawers/drawer-button';
import { Typography } from 'components/atoms/typography';
import { CustomerCenterTab } from 'components/molecules/tabs/customer-center-tab';
import {
  DrawerCustomerCenterBody,
  DrawerCustomerCenterHeader,
  HeadingContainer,
  ImageContainer,
  OptionsContainer,
} from 'components/organisms/drawers/customer-center/styles';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TContenfulImageProp } from 'helpers/types';
import { desktop } from 'helpers/utils/screensizes';
import CloseIcon from 'public/icons/figma/close-small.svg';

interface CustomerCenterDrawerProps {
  label?: string;
  ccHeading: string;
  ccDescription: string;
  ccLogo: {
    mobileImage: TContenfulImageProp;
    desktopImage: TContenfulImageProp;
  };
  ccOptionsCollection: {
    items: {
      title: string;
      description: string;
      url: string;
      icon: TContenfulImageProp;
    }[];
  };
}

const CustomerCenterDrawer = ({
  label,
  ccHeading,
  ccDescription,
  ccLogo,
  ccOptionsCollection,
}: CustomerCenterDrawerProps) => {
  const [open, setOpen] = useState(false);
  const closeDrawer = () => setOpen(false);
  const [isDesktop] = useMediaQuery(desktop);

  const image = isDesktop
    ? ccLogo.desktopImage
      ? ccLogo.desktopImage
      : ccLogo.mobileImage
    : ccLogo.mobileImage
    ? ccLogo.mobileImage
    : ccLogo.desktopImage;
  const { currentDispensary } = useDispensary();
  const showChat = currentDispensary?.dispensaries?.showChatBox;

  return (
    <Drawer
      open={open}
      className={open ? '!top-[70px] !max-h-none rounded-t-md lg:!top-0 lg:rounded-t-none' : 'hidden'}
      onChange={(status) => setOpen(status)}
      label={label}
      buttonType="tertiary"
      buttonClassName={'!p-0 !text-grey-400 hover:!text-leaf font-normal hover:no-underline'}
      desktopWidth={796}
      transition={{ type: 'tween', duration: 1.2 }}
      overlayProps={{ className: 'bg-overlay !backdrop-blur-none fixed' }}
    >
      <DrawerCustomerCenterHeader>
        <Typography
          variant="body-small"
          className="capitalize !text-grey-400 [flex:_1_0_0]"
          additionalContext={{
            testId: 'customerCenterDrawerHeading',
            componentType: 'customer_center',
            tagRole: 'drawer_heading',
            skipPageType: true,
          }}
        >
          {label}
        </Typography>
        <IconButton data-testid="close-button-icon" onClick={closeDrawer} button-type={'primary'} color={'white'}>
          <CloseIcon color="#6F7263" />
        </IconButton>
      </DrawerCustomerCenterHeader>
      <DrawerCustomerCenterBody>
        <ImageContainer>
          <Image
            src={image.url}
            objectFit="contain"
            width={image.width}
            height={image.height}
            alt={image.title}
            loader={(options) => options.src}
            data-testid="customerCenterImage"
          />
        </ImageContainer>
        <HeadingContainer>
          <Typography
            additionalContext={{
              testId: 'customerCenterHeading',
              componentType: 'customer_center',
              tagRole: 'heading',
              skipPageType: true,
            }}
            variant="h3"
            className="mb-[18px]"
          >
            {ccHeading}
          </Typography>
          <Typography
            additionalContext={{
              testId: 'customerCenterDescription',
              componentType: 'customer_center',
              tagRole: 'description',
              skipPageType: true,
            }}
            variant="small"
            className="!text-grey-400"
          >
            {ccDescription}
          </Typography>
        </HeadingContainer>
        <OptionsContainer data-testid="customerCenterOptionsContainer">
          {ccOptionsCollection?.items?.map((option, index) => {
            if (option.url === '#chat' && !showChat) return <></>;
            return <CustomerCenterTab key={index} {...option} closeDrawer={closeDrawer} />;
          })}
        </OptionsContainer>
      </DrawerCustomerCenterBody>
    </Drawer>
  );
};

export default CustomerCenterDrawer;
