import { useState } from 'react';
import { Dialog, DialogBody } from '@material-tailwind/react/components/Dialog';
import { ButtonType, CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Drawer, { DrawerProps } from 'components/atoms/drawers/drawer-button';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import ModalCenteredBody, { ModalCenteredBodyProps } from './model-centered-body';

type ModalCenteredProps =
  | {
      defaultOpen?: boolean;
      customButton?: (onClick: any) => any;
      onClick?: () => any;
      closeModalOnExitClick?: boolean;
      hideCtaButton?: boolean;
      outsideControl?: false;
      reverseButtonOrder?: boolean;
      cancelButtonType?: ButtonType;
      ctaButtonType?: ButtonType;
      hideModalCta?: boolean;
    }
  | {
      customButton?: (onClick: any) => any;
      onClick?: () => any;
      closeModalOnExitClick?: boolean;
      hideCtaButton?: boolean;
      outsideControl?: true;
      openFromParent: boolean;
      handleOpenFromParent(x: boolean): void;
      reverseButtonOrder?: boolean;
      cancelButtonType?: ButtonType;
      ctaButtonType?: ButtonType;
      hideModalCta?: boolean;
    };

const ModalCentered = (props: ModalCenteredProps & ModalCenteredBodyProps & DrawerProps) => {
  const {
    image,
    title,
    content,
    label = '',
    buttonType = 'primary',
    color = 'green',
    buttonClassName,
    ctaLink,
    buttonCloseLabel,
    buttonCtaLabel,
    customButton,
    onClick,
    closeModalOnExitClick,
    hideCtaButton,
    outsideControl,
    reverseButtonOrder,
    cancelButtonType,
    ctaButtonType,
    hideModalCta,
  } = props;
  const [isOpen, setIsOpen] = useState('defaultOpen' in props ? props.defaultOpen : null);
  const [isTablet] = useMediaQuery(tablet);
  const handleOpen = outsideControl ? () => props.handleOpenFromParent(true) : () => setIsOpen(!isOpen);
  return isTablet ? (
    <>
      {hideCtaButton ? null : customButton ? (
        customButton({ onClick: handleOpen })
      ) : (
        <CommonButton button-type={buttonType} color={color} onClick={handleOpen} className={buttonClassName}>
          {label}
        </CommonButton>
      )}
      {isOpen && <div data-testid="modal-center-dialog-component" />}
      <Dialog open={outsideControl ? props.openFromParent : isOpen} handler={handleOpen}>
        <DialogBody className="!p-0">
          <ModalCenteredBody
            image={image}
            title={title}
            content={content}
            onClose={outsideControl ? () => props.handleOpenFromParent(false) : () => setIsOpen(false)}
            ctaLink={ctaLink}
            buttonCloseLabel={buttonCloseLabel}
            buttonCtaLabel={buttonCtaLabel}
            onClick={onClick}
            closeModalOnExitClick={closeModalOnExitClick}
            reverseButtonOrder={reverseButtonOrder}
            cancelButtonType={cancelButtonType}
            ctaButtonType={ctaButtonType}
            hideModalCta={hideModalCta}
          />
        </DialogBody>
      </Dialog>
    </>
  ) : (
    <Drawer
      open={outsideControl ? props.openFromParent : isOpen}
      className={'rounded-t-md lg:rounded-t-none'}
      onChange={(status) => (outsideControl ? () => props.handleOpenFromParent(status) : setIsOpen(status))}
      label={label}
      mobileHeight={472}
      button-type={buttonType}
      buttonClassName={buttonClassName}
      customButton={customButton}
      overlayProps={{ className: 'bg-overlay !backdrop-blur-none h-screen z-[9999]' }}
    >
      <div data-testid="modal-center-drawer-component" />
      <ModalCenteredBody
        image={image}
        title={title}
        content={content}
        onClose={outsideControl ? () => props.handleOpenFromParent(false) : () => setIsOpen(false)}
        ctaLink={ctaLink}
        buttonCloseLabel={buttonCloseLabel}
        buttonCtaLabel={buttonCtaLabel}
        onClick={onClick}
        reverseButtonOrder={reverseButtonOrder}
        cancelButtonType={cancelButtonType}
        ctaButtonType={ctaButtonType}
        hideModalCta={hideModalCta}
      />
    </Drawer>
  );
};

export default ModalCentered;
