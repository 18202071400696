import tw from 'tailwind-styled-components';

export const GeolocationBarWrapper = tw.div`
    items-center 
    justify-center 
    px-5 
    w-full
    bg-light
    py-[6px] 
    md:space-x-2 
    md:py-2
    flex
    border-b
    border-grey-100
`;
