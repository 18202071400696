import React from 'react';
import Image from 'next/image';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { TContenfulImageProp } from 'helpers/types';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { generateOhioLink } from 'helpers/utils/generate-oh-link';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import {
  HeaderChangeDispensaryDesktopBox,
  HeaderChangeDispensaryDesktopImageBox,
  HeaderChangeDispensaryDesktopList,
  HeaderChangeDispensaryDesktopTitleBox,
  HeaderChangeDispensaryDesktopWrapper,
} from './styles';

type TProps = {
  toggleModal: (name: string) => void;
  data: any;
  image: TContenfulImageProp;
  onPressLink?: (stateName: string, name: string) => void;
  host: string;
};
const HeaderChangeDispensaryDesktop = ({ toggleModal, data, image, onPressLink, host }: TProps) => {
  const createURL = (slug: string) => {
    const link = slug?.includes('ohio') && !host?.includes('oh') ? generateOhioLink(host, slug) : host + slug;
    return link;
  };

  return (
    <HeaderChangeDispensaryDesktopWrapper data-testid="header-change-dispensary-desktop">
      <HeaderChangeDispensaryDesktopTitleBox>
        <div className="flex flex-row items-center gap-5 pb-5">
          <IconButton
            button-type="primary"
            className="!bg-kief !text-leaf"
            color="green"
            size="sm"
            onClick={() => toggleModal(THeaderState.DISPENSARIES)}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h3" className="!text-kief">
            {data?.state?.state?.stateName}
          </Typography>
        </div>
      </HeaderChangeDispensaryDesktopTitleBox>
      <HeaderChangeDispensaryDesktopBox>
        <HeaderChangeDispensaryDesktopList>
          {data?.dispensaries?.map((item, index) => (
            <li key={index}>
              <Typography
                onClick={() => onPressLink(data?.state?.state?.stateName, item?.dispensaryInformation?.title)}
                as={'a'}
                variant="body-large"
                className="text-white"
                target={!createURL(item?.slug)?.includes(host) ? '_blank' : '_self'}
                href={createURL(item?.slug)}
              >
                {item?.dispensaryInformation?.title}
              </Typography>
            </li>
          ))}
        </HeaderChangeDispensaryDesktopList>
        {image && (
          <HeaderChangeDispensaryDesktopImageBox>
            <Image
              alt={image?.alt}
              width={image?.height}
              height={image?.width}
              src={image?.url}
              loader={(res) => res.src}
            />
          </HeaderChangeDispensaryDesktopImageBox>
        )}
      </HeaderChangeDispensaryDesktopBox>
    </HeaderChangeDispensaryDesktopWrapper>
  );
};

export default HeaderChangeDispensaryDesktop;
