import Image, { ImageProps } from 'next/image';
import tw from 'tailwind-styled-components';
import CloseIcon from 'public/icons/figma/close-small.svg';

export const ModalCenteredBodyContainer = tw.div`
    flex
    flex-col
    justify-end
    items-center
    gap
    shrink-0
    basis-0

    border-t-md

    gap-8
    pt-12
    pb-14
    px-[60px]
    md:justify-center
    md:gap-5
    md:px-10
    md:py-10

    lg:gap-12
    lg:py-16
    lg:px-[60px]
`;

export const ModalCenteredBodyInnerContainer = tw.div`
    flex
    flex-col
    items-center
    self-stretch
    gap-8

    md:gap-5

    lg:gap-12
`;

export const ModalCenteredBodyInnerContent = tw.div`
    flex
    flex-col
    items-center
    gap-6
    self-stretch
    md:gap-5
    lg:gap-12
`;

export const ModalCenteredBodyContentSection = tw.div`
    flex
    flex-col
    items-center

    gap-6
    md:gap-5
    lg:gap-6
`;

export const ModalCenteredBodyContentTextSection = tw.div`
    lg:!w-[360px]
    lg:self-center
`;

export const ModalCenteredBodyButtonSection = tw.div`
    flex
    flex-row
    gap-4
`;

export const ModalCenteredBodyCloseIcon = tw(CloseIcon)<{ onClick: (e: any) => any }>`
    h-6
    w-6
    cursor-pointer
    absolute
    text-grey-400
    top-5
    right-5

    md:top-8
    md:right-8
`;

export const ModalCenteredBodyImage: React.FC<ImageProps & { ariaLabel?: string }> = tw(Image)`
    flex
    items-center
    rounded-md
    bg-leaf

    h-24
    max-h-[96px]
    py-[11px]
    pr-[17px]
    pl-[7px]

    md:h-[152px]
    md:py-6
    md:pr-12
    md:pl-9

    !h-[74px]
    !w-[86px]
    !bg-leaf
    md:!h-[104px]
    md:!w-[120px]
    lg:!h-[104px]
    lg:!w-[120px]
`;
