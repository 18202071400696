import { useRef, useState } from 'react';
import Slider from 'react-slick';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, ICtaBtn, IRichTextData } from 'helpers/types/common.type';
import { tablet } from 'helpers/utils/screensizes';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import HomeSliderCard from './slidercards';

interface ICtaCollection {
  items: ICtaBtn[];
}

type TSliderProps = {
  title: string;
  description: IRichTextData;
  theme: string;
  ctaCollection: ICtaCollection;
  image: IContentfulImage;
  mobileImage: IContentfulImage;
  bannerType?: string;
  internalName?: string;
};

type TProps = {
  homeSliderData: TSliderProps[];
  placement?: number;
  host: string;
};

const HeroCarousel = ({ homeSliderData, placement, host }: TProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSegmentEvent = (type: string, item: TSliderProps, index: number, options?: object) => {
    const bannerClickedData = {
      placement: placement + 1,
      banner_position: index + 1,
      banner_internal_name: item?.internalName,
      banner_title: item?.title || '',
      banner_image_main: isTablet ? item?.image?.url : item?.mobileImage?.url || item?.image?.url,
      banner_type: item?.bannerType || '',
      link_name: item?.ctaCollection?.items[0]?.title || '',
      link_type: 'hyperlink',
      ...options,
    };
    bannerTrack(type, bannerClickedData);
  };
  const pageChange = (pageNumber: number) => {
    const item = homeSliderData[currentIndex];
    handleSegmentEvent(SegmentEvents.BANNER_SCROLLED, item, pageNumber, {
      banner_scroll_direction: currentIndex > pageNumber ? 'left' : 'right',
      banner_position: currentIndex,
    });
    slickRef.current.slickGoTo(pageNumber);
  };

  const settings = {
    lazyLoading: 'ondemand',
    autoplay: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 600,
    autoplaySpeed: 6000,
  };

  const slickRef = useRef(null);

  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED, homeSliderData[currentIndex], currentIndex);
  });

  const slides =
    homeSliderData?.filter(
      (item: TSliderProps) => item?.image && item?.title && item?.ctaCollection?.items?.length > 0,
    ) || [];

  return (
    <div ref={targetRef}>
      <Slider
        {...settings}
        afterChange={(newIndex) => {
          setCurrentIndex(newIndex);
        }}
        ref={slickRef}
        data-testid="slider"
        infinite={slides?.length > 1}
      >
        {Array.isArray(slides) &&
          slides.map((item: TSliderProps, index: number) => {
            return (
              <HomeSliderCard
                key={item.title.replaceAll(' ', '_') + index}
                index={index}
                host={host}
                title={item?.title}
                description={item?.description}
                cta={item?.ctaCollection?.items[0]}
                chooseCardTemplate={item?.theme}
                image={item?.image}
                mobileImage={item?.mobileImage ? item?.mobileImage : item?.image}
                handlePageChange={pageChange}
                itemsCount={slides.length}
                handleSegmentEvent={() => handleSegmentEvent(SegmentEvents.BANNER_CLICKED, item, index)}
              />
            );
          })}
      </Slider>
    </div>
  );
};

export default HeroCarousel;
