import tw from 'tailwind-styled-components';
import CloseIconFigma from 'public/icons/figma/close-small.svg';

export const DrawerContainer = tw.div`
  mr-4
`;

export const DrawerHeader = tw.div`
  flex
  h-11
  lg:h-20
  justify-end
  pt-5
  lg:pt-12
  pr-5
  lg:pr-[38px]
  lg:pb-2
  items-center
`;

export const DrawerCloseIcon: any = tw(CloseIconFigma)`
  h-6
  w-6
  cursor-pointer
  text-grey-480
`;

export const DrawerCustomerCenterHeader = tw.div`
    flex
    justify-center
    items-center
    self-stretch
    p-5
    gap-2.5
    lg:gap-6
    lg:p-[48px_60px_40px_60px]
    cursor-auto
`;

export const DrawerCustomerCenterBody = tw.div`
    flex
    flex-col
    items-start
    border-grey-200

    border-t
    lg:pt-8
    lg:px-[120px]
    p-5
    !cursor-auto
    h-[calc(100%_-_150px)]
    lg:h-[calc(100%_-_150px)]
    overflow-y-auto
`;

export const ImageContainer = tw.div`
    mx-auto
`;

export const HeadingContainer = tw.div`
    my-8
    text-center
    max-w-[420px]
    mx-auto
`;

export const OptionsContainer = tw.div`
  grid
  gap-y-4
  w-full
`;
