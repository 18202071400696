import tw from 'tailwind-styled-components';

export const SearchContainer = tw.div`
  h-screen 
  w-screen
  fixed
  bg-dark 
  bg-opacity-60
`;
export const SearchInputContainer = tw.div`
  flex 
  items-center
  gap-2
  h-[88px] 
  w-full 
  grid-cols-6 
  bg-white 
  px-5 
  py-6 
  lg:h-[168px] 
  lg:px-[60px]
`;

export const InputTextContainer = tw.div`
  w-full`;

export const SearchInput = tw.input`
  w-full 
  border-none 
  font-antarctican 
  text-2xl 
  leading-6 
  tracking-[0.48px] 
  !text-leaf 
  lg:text-[42px] 
  lg:font-medium 
  lg:leading-10 
  lg:tracking-[0.84px]`;

export const SearchBox = tw.form<{ $isLowerCase: boolean }>`
  ${(p) => (p.$isLowerCase ? 'pb-2' : '')}
`;
