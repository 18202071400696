import React from 'react';
import Head from 'next/head';
import { ISEOData } from 'helpers/types/organisms/global/page-seo';

type TPageSeo = {
  data: ISEOData;
};

const PageSEOComponent = ({ data }: TPageSeo) => {
  if (!data) {
    return <React.Fragment />;
  }

  return (
    <Head>
      <meta key="og_locale" property="og:locale" content="en_US" />
      {data?.seoTitle && (
        <React.Fragment>
          <title key="title">{data.seoTitle}</title>
          <meta key="og_title" property="og:title" content={data.seoTitle} />
          <meta key="twitter:title" property="twitter:title" content={data.seoTitle} />
        </React.Fragment>
      )}
      {data?.description && (
        <React.Fragment>
          <meta name="description" content={data.description} />
          <meta key="og_description" property="og:description" content={data.description} />
          <meta key="twitter:description" property="twitter:description" content={data.description} />
        </React.Fragment>
      )}
      {data?.canonicalUrl && (
        <React.Fragment>
          <link rel="canonical" href={data.canonicalUrl} />
          <meta key="og_url" property="og:url" content={data.canonicalUrl} />
        </React.Fragment>
      )}
      <meta
        name="robots"
        content={`${data?.noIndex ? 'noindex' : 'index'}, ${
          data?.noFollow ? 'nofollow' : 'follow'
        }, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      />
      {data?.ogType && <meta key="og_type" property="og:type" content={data?.ogType} />}
      {data?.ogSiteName && <meta key="og_site_name" property="og:site_name" content={data?.ogSiteName} />}
      {data?.ogArticlePublisher && <meta property="article:publisher" content={data?.ogArticlePublisher} />}
      {data?.ogArticleModifiedTime && <meta property="article:modified_time" content={data?.ogArticleModifiedTime} />}
      {data?.ogImage ? (
        <React.Fragment>
          <meta key="og_image" property="og:image" content={data?.ogImage?.url} />
          <meta key="og_image:alt" property="og:image:alt" content={data?.ogImage?.title} />
          <meta key="og_image:width" property="og:image:width" content={data?.ogImage?.width?.toString()} />
          <meta key="og_image:height" property="og:image:height" content={data?.ogImage?.height?.toString()} />
          <meta key="twitter:image" property="twitter:image" content={data?.ogImage?.url} />
        </React.Fragment>
      ) : null}
      {data?.organizationSchemaMarkupCode && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data?.organizationSchemaMarkupCode) }}
        />
      )}
      {data?.onPageSchemaMarkupCode && (
        <script
          defer
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data?.onPageSchemaMarkupCode) }}
        />
      )}
      {data?.productSeoMetaData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data?.productSeoMetaData) }}
        />
      )}
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      <meta key="twitter:site" name="twitter:site" content={'@risecannabis'} />
      <meta name="google-site-verification" content="WeRDa7EKIC6N1DAY-m7w4d6Siy2L1jnAMPXUGeZzhAU" />
      <meta name="yandex-verification" content="1c16249b187e86f6" />
      <meta name="msvalidate.01" content="6216F72F6E2523E7EB86CC2726849189" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Head>
  );
};

export default PageSEOComponent;
