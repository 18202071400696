import tw from 'tailwind-styled-components';

export const CustomerCenterTabContainer = tw.div`
    p-5
    md:py-7
    md:px-8
    bg-grey-100
    inline-flex
    w-full
`;

export const TextContainer = tw.div`
    ml-5
    md:ml-8
`;

export const ImageContainer = tw.div`
    min-w-[24px]
    min-h-[24px]
    flex
    items-center
`;
