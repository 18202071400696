import { AnchorElement } from 'helpers/types';
import { AnchorLinkContainer, DownArrowIcon, StyledNavItem, UpArrowIcon } from '../styles';

export type Variant = 'light' | 'dark';
export type TNavType = 'dropdown' | 'simple';

export interface TProps extends AnchorElement {
  children: string;
  variant: Variant;
  isActive?: boolean;
  isDropDownOpen?: boolean;
  type?: TNavType;
}

const NavItem = ({
  children,
  variant,
  isActive = false,
  href,
  isDropDownOpen = false,
  type = 'simple',
  target,
  ...rest
}: TProps) => {
  return type === 'dropdown' ? (
    <AnchorLinkContainer {...rest}>
      <StyledNavItem $variant={variant} $isActive={isActive} $isDropDownOpen={isDropDownOpen}>
        {children}
      </StyledNavItem>
      {!isDropDownOpen ? (
        <DownArrowIcon data-testid={'down-arrow'} $variant={variant} />
      ) : (
        <UpArrowIcon data-testid={'up-arrow'} $variant={variant} $isDropDownOpen={isDropDownOpen} />
      )}
    </AnchorLinkContainer>
  ) : (
    <a href={href || ''} target={target}>
      <StyledNavItem $variant={variant} $isActive={isActive} $isDropDownOpen={isDropDownOpen} {...rest}>
        {children}
      </StyledNavItem>
    </a>
  );
};

export default NavItem;
