import { useRef, useState } from 'react';
import Slider from 'react-slick';
import { FilterPillButton } from 'components/atoms/buttons/filter-pill';
import { NextIcon, PrevIcon } from 'components/atoms/pagination/style';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  FilterCarouselContainer,
  NextIconContainer,
  NextIconGredient,
  NextIconWrapper,
  PreviousIconContainer,
  PreviousIconGredient,
  PreviousIconWrapper,
} from './styles';
type TProps = {
  data: any;
  onClickFilterPill: (data?: any) => void;
  filters: any;
  defaultSelected?: string;
};
const FilterCarousel = ({ data, onClickFilterPill, filters, defaultSelected }: TProps) => {
  const [isNextButtonHidden, setIsNextButtonHidden] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isPreviousButtonHidden = currentIndex === 0;
  const slickRef = useRef(null);
  const handlePrevious = () => {
    slickRef.current.slickPrev();
  };

  const handleNext = () => {
    slickRef.current.slickNext();
  };

  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    variableWidth: true,
  };
  return (
    <FilterCarouselContainer data-testid="filter-carousel">
      {!isPreviousButtonHidden && (
        <PreviousIconContainer>
          <PreviousIconWrapper>
            <PrevIcon
              $color={'leaf'}
              onClick={handlePrevious}
              className={` ${'mr-2 h-6 w-6'} `}
              data-testid="previous-button"
            />
          </PreviousIconWrapper>
          <PreviousIconGredient />
        </PreviousIconContainer>
      )}
      {!isNextButtonHidden && (
        <NextIconContainer>
          <NextIconGredient />
          <NextIconWrapper>
            <NextIcon
              $color={'leaf'}
              onClick={handleNext}
              className={` ${'mr-2 h-6 w-6'} `}
              data-testid="next-button"
            />
          </NextIconWrapper>
        </NextIconContainer>
      )}
      <Slider
        ref={slickRef}
        {...settings}
        beforeChange={(currentSlide, nextSlide) => {
          setIsNextButtonHidden(currentSlide === nextSlide);
          setCurrentIndex(nextSlide);
        }}
      >
        {data &&
          data?.map((item, index) => {
            const selected =
              filters &&
              filters?.some(
                (dataInfo) =>
                  dataInfo?.name?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase() ===
                  item?.title?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase(),
              );
            return (
              <FilterPillButton
                defaultSelected={
                  defaultSelected?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase() ===
                    item?.title?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase() || selected
                }
                label={item?.title}
                key={index}
                onClick={onClickFilterPill}
              />
            );
          })}
      </Slider>
    </FilterCarouselContainer>
  );
};

export default FilterCarousel;
