import tw from 'tailwind-styled-components';

export const CookieButton = tw.button`
  ot-sdk-show-settings
  flex
  cursor-pointer
  items-center
  !text-grey-400
  hover:!text-leaf
  !border-0
  !p-0
  text-sm
  !leading-4
  !tracking-[0.14px]
  xl:!text-base
  xl:leading-5
  xl:!tracking-[0.16px]
  !not-italic
  !font-normal
  !font-arial
  text-leaf
  md:!text-[15px]
  md:!leading-[18px]
  md:!tracking-[0.15px]
  hover:!bg-transparent
`;
