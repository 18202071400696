import React from 'react';
import { TInitialStateProps } from '../..';
import HeaderShopSectionList from './header-shop-section-list';
type TProps = {
  toggleModal: (name: string) => void;
  modal: TInitialStateProps;
  data: any;
};

const HeaderShopSectionMobile = ({ toggleModal, data, modal }: TProps) => {
  if (modal?.categories?.isOpen) {
    return (
      <HeaderShopSectionList
        title="Categories"
        data={data?.pageHomeCollection?.items?.[0]?.categories?.items?.[0]?.facetsCollection?.items}
        toggleModal={toggleModal}
      />
    );
  }
  if (modal?.brands?.isOpen) {
    return (
      <HeaderShopSectionList
        title="Brands"
        data={data?.pageHomeCollection?.items?.[0]?.brands?.facetsCollection?.items}
        toggleModal={toggleModal}
        ctaName="See all brands"
      />
    );
  }
  if (modal?.usages?.isOpen) {
    return (
      <HeaderShopSectionList
        title="Usages"
        data={data?.pageHomeCollection?.items?.[0].usages?.items?.[0]?.facetsCollection?.items}
        toggleModal={toggleModal}
      />
    );
  }
};

export default HeaderShopSectionMobile;
