import axios from 'axios';
import Auth from './utils/cognito';

const EXTERNAL_USER_API = '/external_user_api/v1';
const janeRootsClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_IHJ_PROXY_HOST + '/roots',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

janeRootsClient?.interceptors?.request?.use(
  async (req) => {
    const session = await Auth.getSession();
    req.headers.Authorization = session?.getAccessToken()?.getJwtToken();
    return req;
  },
  (err) => {
    return Promise.reject(err);
  },
);

const getUser = async () => {
  try {
    return await janeRootsClient.get(`${EXTERNAL_USER_API}/user`);
  } catch (error) {
    console.error('Jane GetUser Err:-', error);
  }
};

const getTransactionHistory = async (pageSize: number, pagination_id: number) => {
  return await janeRootsClient.get(
    `${EXTERNAL_USER_API}/carts?page_size=${pageSize}${pagination_id ? `&pagination_id=${pagination_id}` : ''}`,
  );
};

const getOrderById = async (orderId: string) => {
  return await janeRootsClient.get(`${EXTERNAL_USER_API}/carts/${orderId}`);
};

export const getPostCheckoutCart = async (uuid = '') => {
  try {
    return await janeRootsClient.get(`${EXTERNAL_USER_API}/${uuid}`);
  } catch (e) {
    console.error(e);
    return { error: 400, data: {} };
  }
};

export const cancelUserCart = async (uuid) => {
  try {
    return await janeRootsClient.post(`${EXTERNAL_USER_API}/carts/${uuid}/cancel`);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getUser, getTransactionHistory, getOrderById, getPostCheckoutCart, cancelUserCart };
