import tw from 'tailwind-styled-components';
export const HeaderShopSectionListWrapper = tw.div`
    block 
    bg-leaf 
    px-5 
    pb-[104px] 
    pt-6 
    lg:hidden
`;

export const HeaderShopSectionListBox = tw.div`
    flex 
    w-full 
    flex-row 
    items-center 
    gap-5 
    pb-5
`;

export const HeaderShopSectionListCard = tw.ul`
    flex 
    flex-col 
    gap-8
    pt-2
    h-[425px] 
    overflow-y-scroll 
`;
