import { Typography } from 'components/atoms/typography';
import LocationIcon from 'public/icons/figma/location.svg';
import { GeolocationBarWrapper } from './styles';

type TProps = {
  locationName: string;
  setDrawerOpen: (state: boolean) => void;
};

const GeolocationBar = ({ locationName, setDrawerOpen }: TProps) => {
  return (
    <GeolocationBarWrapper data-testid="GeolocationBar">
      <LocationIcon data-testid="icon" fontSize={'20px'} className="min-w-[24px] self-end text-resin" />
      <Typography variant="body-small" className="!text-grey-400">
        Shopping
      </Typography>
      <Typography
        variant="body-small-bold"
        className="ml-2 max-w-full cursor-pointer truncate text-center hover:underline md:ml-0"
        onClick={() => setDrawerOpen(true)}
      >
        {locationName}
      </Typography>
    </GeolocationBarWrapper>
  );
};

export default GeolocationBar;
