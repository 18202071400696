import tw from 'tailwind-styled-components';
type TVariantProps = {
  $variant: 'dark' | 'light';
};
export const HeaderWrapper = tw.header<TVariantProps>`
    fixed 
    top-0
    right-0
    z-[25] 
    block 
    w-full 
    ${(p) => (p.$variant === 'dark' ? 'bg-leaf' : 'bg-white')}
`;

export const HeaderBox = tw.div`
  relative 
  mx-auto
`;
