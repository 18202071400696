import React from 'react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { generateOhioLink } from 'helpers/utils/generate-oh-link';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import {
  HeaderChangeDispensaryMobileIconButton,
  HeaderChangeDispensaryMobileList,
  HeaderChangeDispensaryMobileWrapper,
} from './styles';

type TProps = {
  toggleModal: (name: string) => void;
  data: any;
  onPressLink?: (stateName: string, name: string) => void;
  host: string;
};

const HeaderChangeDispensaryMobile = ({ toggleModal, data, onPressLink, host }: TProps) => {
  const createURL = (slug: string) => {
    const link = slug?.includes('ohio') && !host?.includes('oh') ? generateOhioLink(host, slug) : host + slug;
    return link;
  };

  return (
    <HeaderChangeDispensaryMobileWrapper data-testid="header-change-dispensary-mobile">
      <HeaderChangeDispensaryMobileIconButton>
        <IconButton
          button-type="primary"
          className="!bg-kief !text-leaf"
          color="green"
          onClick={() => toggleModal(THeaderState.DISPENSARIES)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h2" className="!text-kief">
          {data?.state?.state?.stateName}
        </Typography>
      </HeaderChangeDispensaryMobileIconButton>
      <HeaderChangeDispensaryMobileList>
        {data?.dispensaries?.map((item, index) => (
          <li key={index}>
            <Typography
              onClick={() => onPressLink(data?.state?.state?.stateName, item?.dispensaryInformation?.title)}
              as={'a'}
              variant="body-large"
              className="text-white"
              target={!createURL(item?.slug)?.includes(host) ? '_blank' : '_self'}
              href={createURL(item?.slug)}
            >
              {item?.dispensaryInformation?.title}
            </Typography>
          </li>
        ))}
      </HeaderChangeDispensaryMobileList>
    </HeaderChangeDispensaryMobileWrapper>
  );
};

export default HeaderChangeDispensaryMobile;
