import tw from 'tailwind-styled-components';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import ChevronUpIcon from 'public/icons/figma/chevron-up.svg';
import RewardGiltIcon from 'public/icons/figma/reward-gift.svg';
import { AnchorElement, DivElement, HeadingElement } from '../../../helpers/types';
import { Variant } from './nav-item/nav-item';
import { TabVariant } from './tab-item/tab-item';

interface IStyledNavItem extends AnchorElement {
  $variant: Variant;
  $isActive?: boolean;
  $isDropDownOpen?: boolean;
}

export const AnchorLinkContainer = tw.a`
  flex
  items-center
  group
`;

export const StyledNavItem: React.FC<IStyledNavItem> = tw.div`
  font-antarctican
  py-2
  font-bold
  text-2xl
  leading-6
  tracking-[0.24px]
  cursor-pointer
  ${({ $variant, $isActive, $isDropDownOpen }: IStyledNavItem) =>
    $variant === 'light'
      ? `${
          $isDropDownOpen ? 'border-b-leaf-dark !text-leaf-dark border-b-[3px]' : ''
        } text-leaf group-hover:!text-leaf-dark group-hover:border-b-[3px] hover:border-b-[3px]
      border-solid group-hover:border-b-leaf-dark hover:text-leaf-dark hover:border-b-leaf-dark ${
        $isActive && !$isDropDownOpen ? 'border-b-[3px] border-b-resin' : ''
      }`
      : ''}
  ${({ $variant, $isActive, $isDropDownOpen }: IStyledNavItem) =>
    $variant === 'dark'
      ? `${
          $isDropDownOpen ? 'text-kief border-b-[3px] border-b-kief' : 'text-white'
        } group-hover:text-kief hover:text-kief group-hover:border-b-[3px] hover:border-b-[3px]
      border-solid group-hover:border-b-kief hover:border-b-kief ${
        $isActive && !$isDropDownOpen ? 'border-b-[3px] border-b-resin' : ''
      }`
      : ''}
`;

export const DownArrowIcon: React.FC<IStyledNavItem> = tw(ChevronDownIcon)`
  w-6
  h-6
  cursor-pointer
  ${({ $variant }: IStyledNavItem) => ($variant === 'dark' ? 'text-white group-hover:text-kief' : '')}
  ${({ $variant }: IStyledNavItem) => ($variant === 'light' ? 'text-leaf-dark ' : '')}
`;

export const UpArrowIcon: React.FC<IStyledNavItem> = tw(ChevronUpIcon)`
  w-6
  h-6
  cursor-pointer
  ${({ $variant, $isDropDownOpen }: IStyledNavItem) =>
    $variant === 'dark' ? `${$isDropDownOpen ? 'text-kief' : 'text-white'} group-hover:text-kief` : ''}
  ${({ $variant }: IStyledNavItem) => ($variant === 'light' ? 'text-leaf-dark' : '')}
`;

export const BreadcrumbSeparator = tw(ChevronRightIcon)`
  w-6
  h-6
  text-grey-480
  mx-4
`;

export const BreadcrumbMobile = tw.li`
  lg:hidden
  flex
  items-center
`;

export const BreadcrumbPrevousIcon = tw(ChevronLeftIcon)`
  w-6
  h-6
  text-grey-480
  mr-2
`;

interface IStyledTabItem extends HeadingElement {
  $variant: TabVariant;
  $isActive: boolean;
}

export const StyledTabItem: React.FC<IStyledTabItem> = tw.h4`
  cursor-pointer
  ${({ $variant, $isActive }: IStyledTabItem) =>
    $variant === 'large'
      ? `pb-2 font-antarctican tracking-[0.64px] lg:tracking-[0.84px] font-medium	text-2xl md:text-[42px]  leading-6 md:leading-10 ${
          $isActive ? 'text-leaf inline-block	 border-solid border-b-[3px] border-b-resin' : 'text-grey-480'
        }`
      : ''}
  ${({ $variant, $isActive }: IStyledTabItem) =>
    $variant === 'small'
      ? `pb-2 font-antarctican tracking-[0.2px] lg:tracking-[0.24px] font-bold	text-xl md:text-2xl  leading-5 md:leading-6 ${
          $isActive ? 'ml-1 lg:ml-[6px] text-grey-500 border-solid border-b-[2px] border-b-resin' : 'text-grey-480'
        }`
      : ''}
  ${({ $variant, $isActive }: IStyledTabItem) =>
    $variant === 'small-subnav'
      ? `font-bold font-arial text-sm leading-4 tracking-[0.14px] ${
          $isActive ? 'text-leaf inline-block border-solid border-b-[1px] border-b-resin' : 'text-grey-480'
        }`
      : ''}
`;

export const TabItemRightIcon = tw(ChevronRightIcon)`
  w-6
  h-6
  text-resin
  hidden
  lg:block
`;

export const TabItemContainer = tw.div`
  flex
`;

interface ISideBarItemContainer extends DivElement {
  $isActive: boolean;
}

export const SideBarItemContainer: React.FC<ISideBarItemContainer> = tw.div`
  w-full
  md:w-[396px]
  h-8
  md:h-14
  pr-[42px]
  lg:pr-[60px]
  my-4
  md:my-0
  flex
  items-center
  ${({ $isActive }: ISideBarItemContainer) =>
    $isActive ? 'border-solid border-l-[3px] border-l-resin md:border-l-0  md:border-r-[3px] md:border-r-resin' : ''}
`;

export const SideBarItemIcon = tw(RewardGiltIcon)`
  w-6
  h-6
  text-grey-480
  mx-4
  md:mx-0
  md:mr-5
`;

export const SideBarItemCount = tw.div`
  w-5
  md:w-6
  h-5
  md:h-6
  rounded-[10px]
  md:rounded-xl
  bg-resin
  text-white
  font-bold
  text-xs
  leading-3
  md:leading-5
  flex
  items-center
  justify-center
  ml-auto
  font-arial
`;

interface ISideBarItemIconTextBox extends DivElement {
  $isActive: boolean;
}

export const SideBarItemIconTextBox: React.FC<ISideBarItemIconTextBox> = tw.div`
  flex
  items-center
  ${({ $isActive }: ISideBarItemContainer) => ($isActive ? '[&>p]:text-leaf [&>svg]:text-leaf' : '[&>p]:text-grey-400')}
`;
