import Header from 'components/organisms/global/header';
import useDispensary from 'helpers/hooks/use-dispensary';
import { getCurrentStateSnackbarCookieValue } from 'helpers/utils/cookies';
type TProps = {
  data: {
    headerData: any;
    dispensariesListData: any;
    hideServiceLocation?: boolean;
  };
  pageFolder: Record<string, any>;
};

const HeaderComponent = ({ data, pageFolder }: TProps) => {
  const snackBarCookies = pageFolder?.cookies?.stickyBarCookies;
  const geoState = pageFolder?.cookies?.stateID;
  const host = pageFolder?.host;
  const dispensariesListData = data?.dispensariesListData?.dataSource;
  const hideServiceLocation = data?.hideServiceLocation;

  useDispensary(
    Array.isArray(dispensariesListData) ? dispensariesListData : [],
    pageFolder?.cookies?.disID,
    pageFolder?.cookies?.stateID,
    pageFolder?.cookies?.storeID,
  );

  return (
    <Header
      data={data.headerData.dataSource.data}
      dispensariesList={data.dispensariesListData.dataSource}
      geoState={geoState}
      snackBarCookies={getCurrentStateSnackbarCookieValue(snackBarCookies, geoState)}
      host={host}
      hideServiceLocation={hideServiceLocation}
    />
  );
};

export default HeaderComponent;
