import React from 'react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { ReplaceUrlWithHost, getLinkTarget, isLITH } from 'helpers/utils/common';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { HeaderNavBarMobileBox, HeaderNavBarMobileLink, HeaderNavBarMobileWrapper } from './styles';

type TProps = {
  data: any;
  toggleModal: (title: string, data?: any) => void;
  host?: string;
};

const HeaderNavbarMobile = ({ data, toggleModal, host }: TProps) => {
  const handleOnPressLink = (name: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
      nav_position: 'header',
      link_primary: name,
      link_type: 'dropdown',
    });
  };
  const handleNavItem = (name: string, data?: any) => {
    handleOnPressLink(name);
    switch (name) {
      case 'shop':
        return toggleModal(THeaderState.SHOP, data);
      case 'dispensaries':
        return toggleModal(THeaderState.DISPENSARIES);
      case 'cannabis insider':
      case 'resources':
        return toggleModal(THeaderState.CANNABISINSIDER, { name, data });
    }
  };
  return (
    <HeaderNavBarMobileWrapper id="tabletAndMobilePopUp" data-testid="extended-menu">
      <HeaderNavBarMobileBox>
        {data &&
          data?.map((item, index) => (
            <li key={index}>
              {item?.navigationUrl?.includes('#') ? (
                <HeaderNavBarMobileLink
                  onClick={() =>
                    handleNavItem(item?.navigationLabel.toLowerCase(), item?.childNavigationCollection?.items)
                  }
                >
                  <Typography variant="h2" className="text-white">
                    {isLITH() && item?.navigationLabel?.toLowerCase() === 'cannabis insider'
                      ? 'Resources'
                      : item?.navigationLabel}
                  </Typography>
                  {item?.navigationUrl?.includes('#') && (
                    <IconButton
                      button-type="secondary"
                      className="bg-[rgba(0,0,0,0.40)] !p-2 !text-kief hover:!text-kief"
                      color="green"
                      size="sm"
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  )}
                </HeaderNavBarMobileLink>
              ) : (
                <HeaderNavBarMobileLink
                  target={getLinkTarget(ReplaceUrlWithHost(item?.navigationUrl, host))}
                  href={ReplaceUrlWithHost(item?.navigationUrl, host)}
                >
                  <Typography variant="h2" className="text-white">
                    {isLITH() && item?.navigationLabel?.toLowerCase() === 'cannabis insider'
                      ? 'Resources'
                      : item?.navigationLabel}
                  </Typography>
                </HeaderNavBarMobileLink>
              )}
            </li>
          ))}
      </HeaderNavBarMobileBox>
    </HeaderNavBarMobileWrapper>
  );
};

export default HeaderNavbarMobile;
