import React from 'react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { getLinkTarget, getMenuFiltersLink } from 'helpers/utils/common';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { HeaderShopSectionListBox, HeaderShopSectionListCard, HeaderShopSectionListWrapper } from './styles';

type TProps = {
  toggleModal: (name: string) => void;
  title: string;
  data: any;
  ctaName?: string;
};

const HeaderShopSectionList = ({ data, toggleModal, title, ctaName }: TProps) => {
  const handleOnPressLink = (name: string) => {
    navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
      nav_position: 'header',
      link_final: name,
      link_secondary: title,
      link_primary: 'shop',
      link_type: 'hyperlink',
    });
  };
  return (
    <HeaderShopSectionListWrapper data-testid="header-shop-section-mobile">
      <HeaderShopSectionListBox>
        <IconButton
          button-type="primary"
          className="!bg-kief !text-leaf"
          color="green"
          onClick={() => toggleModal(THeaderState.SHOP)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h2" className="!text-kief">
          {title}
        </Typography>
      </HeaderShopSectionListBox>
      <HeaderShopSectionListCard>
        {data &&
          data?.map((item, index) => (
            <li key={index}>
              <Typography
                as={'a'}
                onClick={() => handleOnPressLink(item?.title)}
                variant="body-large"
                className="flex flex-row items-center justify-between text-white"
                href={getMenuFiltersLink(item?.algoliaId, item?.algoliaValue)}
                target={getLinkTarget(getMenuFiltersLink(item?.algoliaId, item?.algoliaValue))}
              >
                {item?.title}
              </Typography>
            </li>
          ))}
        {ctaName && (
          <IconWithTextButton
            button-type="tertiary"
            color="white"
            icon={<ChevronRightIcon size={20} />}
            iconPosition="after"
            as="a"
            href={getMenuFiltersLink()}
            target={getLinkTarget(getMenuFiltersLink())}
            className="!p-0"
          >
            {ctaName}
          </IconWithTextButton>
        )}
      </HeaderShopSectionListCard>
    </HeaderShopSectionListWrapper>
  );
};

export default HeaderShopSectionList;
