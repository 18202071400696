import { FunctionComponent } from 'react';
import Image from 'next/image';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IImage } from 'helpers/types/forms';
import { tablet } from 'helpers/utils/screensizes';
import { CopyRightTextAnchor, FooterCopyRightSpan, FooterCopySection, ImageContainer } from './styles';

type Legalprops = {
  legalCopyRight: string;
  gtiLogo: {
    desktopImage: IImage;
    mobileImage: IImage;
  };
};

export const FooterLegalCopyRight: FunctionComponent<Legalprops> = ({ legalCopyRight, gtiLogo }) => {
  const isTablet = useMediaQuery(tablet);
  const year = new Date().getFullYear();
  const copyRightLabel = <CopyRightTextAnchor dangerouslySetInnerHTML={{ __html: legalCopyRight }} />;
  return (
    <FooterCopySection>
      <FooterCopyRightSpan>
        © {year} {copyRightLabel}
      </FooterCopyRightSpan>
      {gtiLogo && (
        <ImageContainer>
          <Image
            src={isTablet ? gtiLogo?.desktopImage?.url : gtiLogo?.mobileImage?.url}
            alt={isTablet ? gtiLogo?.desktopImage?.title : gtiLogo?.mobileImage?.title}
            width={isTablet ? gtiLogo?.desktopImage?.width : gtiLogo?.mobileImage?.width}
            height={isTablet ? gtiLogo?.desktopImage?.height : gtiLogo?.mobileImage?.height}
            loader={(options) => options.src}
            placeholder="empty"
          />
        </ImageContainer>
      )}
    </FooterCopySection>
  );
};
