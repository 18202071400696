import { useEffect } from 'react';
import { getCookie, parseCookieGroupPermissions } from 'helpers/utils/cookies';
import { setCookiePermissions } from 'redux/reducers/cookies';
import { store } from 'redux/store';

export const parseCookiesAndDispatch = () => {
  const oneTrustCookie = getCookie('OptanonConsent');
  const cookiePermissions = parseCookieGroupPermissions(oneTrustCookie);
  store.dispatch(setCookiePermissions({ cookieGroups: cookiePermissions }));
};

export const CookieGateComponent = () => {
  useEffect(() => {
    parseCookiesAndDispatch();
  }, []);

  return <div id="cookie-gate-parser" />;
};
