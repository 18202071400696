import { INavigartionTrack, SegementTrackEvent } from './segment-helper';

export type TProps = {
  nav_position: string;
  link_primary?: string;
  link_secondary?: string;
  link_final?: string;
  link_type: string;
};

export const navigationTrack = (type: string, data: TProps) => {
  const values: INavigartionTrack = {
    link_final: data?.link_final || '',
    page_location: typeof window !== 'undefined' && window.location.href,
    ...data,
  };

  SegementTrackEvent(type, values, { sendGeoData: true });
};
