import { useEffect } from 'react';
export const useAutoClosePopUp = (id: string, handleClose: () => void) => {
  useEffect(() => {
    const closePopUp = (e) => {
      e.preventDefault();
      const container = document?.getElementById(id);
      if (container && !container.contains(e.target)) {
        handleClose();
      }
    };
    document.addEventListener('mouseup', closePopUp);
    return () => {
      window?.removeEventListener('mouseup', closePopUp);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
