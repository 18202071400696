import { useEffect } from 'react';
import {
  Drawer as MaterialDrawer,
  DrawerProps as MaterialDrawerProps,
} from '@material-tailwind/react/components/Drawer';
import { ButtonType, Color, CommonButton } from 'components/atoms/buttons/common-buttons/button';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
export interface DrawerProps {
  label?: string;
  buttonClassName?: string;
  buttonType?: ButtonType;
  color?: Color;
  onChange?: (status: boolean) => any;
  desktopWidth?: number;
  mobileHeight?: number;
  overlayProps?: Record<string, any>;
  customButton?: (onClick: any) => any;
}

const DrawerButton = ({
  label = 'Label',
  open = false,
  buttonType = 'primary',
  color = 'green',
  buttonClassName = '',
  onChange,
  className = '',
  transition = { type: 'tween', duration: 0.3 },
  children,
  desktopWidth = 676,
  mobileHeight = 580,
  overlayProps,
  customButton,
}: DrawerProps & MaterialDrawerProps) => {
  const [isDesktop] = useMediaQuery(desktop);
  const openDrawer = () => onChange && onChange(true);
  const closeDrawer = () => onChange && onChange(false);
  const placement = isDesktop ? 'right' : 'bottom';
  const size = isDesktop ? desktopWidth : mobileHeight;

  useEffect(() => {
    onChange && onChange(open);
  }, [open]);

  return (
    <>
      {customButton ? (
        customButton({ onClick: openDrawer })
      ) : (
        <CommonButton button-type={buttonType} color={color} onClick={openDrawer} className={buttonClassName}>
          {label}
        </CommonButton>
      )}
      <div className={open ? '' : 'hidden'}>
        <MaterialDrawer
          open={open}
          size={size}
          onClose={closeDrawer}
          className={className}
          placement={placement}
          transition={transition}
          overlayProps={overlayProps}
        >
          {children}
        </MaterialDrawer>
      </div>
    </>
  );
};

export default DrawerButton;
