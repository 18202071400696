import React from 'react';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { ReplaceUrlWithHost, getLinkTarget, isLITH } from 'helpers/utils/common';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import { useAppSelector } from 'redux/hooks';
import {
  HeaderCannabisInsiderMobileBox,
  HeaderCannabisInsiderMobileList,
  HeaderCannabisInsiderMobileListItem,
  HeaderCannabisInsiderMobileListLink,
  HeaderCannabisInsiderMobileWrapper,
} from './styles';

type TProps = {
  toggleModal: (title: string) => void;
  data: TDataProps;
  onPressLink?: (name: string) => void;
  host?: string;
};
type TDataProps = {
  linkArray: Record<string, any>[];
  name: string;
};
const HeaderCannabisInsiderMobile = ({ toggleModal, data, onPressLink, host }: TProps) => {
  const isOh = useAppSelector((state) => state.hostReducer?.isCurrentHostOH);
  const state = useAppSelector((state) => state.dispensaryList?.currentDispensary);

  return (
    <HeaderCannabisInsiderMobileWrapper>
      <HeaderCannabisInsiderMobileBox>
        <IconButton
          button-type="primary"
          className="!bg-kief !text-leaf"
          color="green"
          onClick={() => toggleModal(THeaderState.NAVBAR)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h2" className="!text-kief">
          {isLITH() ? 'Resources' : data?.name || ''}
        </Typography>
      </HeaderCannabisInsiderMobileBox>
      <HeaderCannabisInsiderMobileList>
        {data?.linkArray &&
          data?.linkArray?.map((item, index) => {
            const url =
              !isOh &&
              state?.state?.state?.stateName?.toLowerCase() === 'ohio' &&
              !item?.navigationUrl?.includes('blog')
                ? item?.navigationUrl?.replace('{{url}}', '{{url-oh}}')
                : item?.navigationUrl;
            return (
              <HeaderCannabisInsiderMobileListItem key={index}>
                <HeaderCannabisInsiderMobileListLink
                  onClick={() => onPressLink(item?.navigationLabel)}
                  target={getLinkTarget(ReplaceUrlWithHost(url, host))}
                  href={ReplaceUrlWithHost(url, host)}
                >
                  <Typography variant="h5" className="text-white">
                    {item?.navigationLabel}
                  </Typography>
                </HeaderCannabisInsiderMobileListLink>
              </HeaderCannabisInsiderMobileListItem>
            );
          })}
      </HeaderCannabisInsiderMobileList>
    </HeaderCannabisInsiderMobileWrapper>
  );
};

export default HeaderCannabisInsiderMobile;
